import { FC, Fragment, memo, PropsWithChildren, useEffect, useState } from "react";
import { AnimatedCardWrapper } from "src/modules/products-screens/products-list/components/animated-card-wrapper";
import { Blueprint } from "src/models/blueprint";

interface BlueprintCardProps {
    blueprintData: Blueprint;
    animated?: boolean;
    className?: string;
}

export const BlueprintCard: FC<BlueprintCardProps & PropsWithChildren> = memo(
    ({ blueprintData, animated, className, children }) => {
        const [isLoading, setIsLoading] = useState(true);

        useEffect(() => {
            const img = new Image();
            img.src = blueprintData.images[0];
            img.onload = () => setIsLoading(false);
        }, [blueprintData.images[0]]);

        const Wrapper = animated ? AnimatedCardWrapper : Fragment;

        return (
            <Wrapper>
                <div className={`relative w-full pb-[100%] rounded-lg overflow-hidden ${className}`}>
                    <div className="absolute inset-0">
                        {isLoading && (
                            <div className="h-full w-full bg-gray-300 animate-pulse" />
                        )}
                        <img
                            src={blueprintData.images[0]}
                            alt={blueprintData.title}
                            draggable={false}
                            className="h-full w-full object-cover"
                        />
                    </div>
                    {children}
                </div>
            </Wrapper>
        );
    }
);
