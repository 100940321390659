import { FC } from "react";
import { Search } from "src/modules/products-screens/components/search";
import { VerticalScroll, BlueprintModelCard, TabNavigation } from "src/modules/blueprints";
import { Blueprint } from "src/models/blueprint";
import FilterIcon from "src/assets/icons/filter-icon.png";
import { Tab } from "src/modules/blueprints/types";

interface DiscoverBlockProps {
    blueprintsData: Blueprint[];
    setActiveTab: (tab: Tab) => void;
    className?: string;
}

export const DiscoverBlock: FC<DiscoverBlockProps> = (
    { blueprintsData, setActiveTab, className }
) => {
    const handleClick = () => {
        console.log("Click");
    };

    return (
        <VerticalScroll className={className}>
            <div className="w-full h-full flex flex-col gap-2 lg:gap-5 py-5 lg:py-10">
                <TabNavigation activeTab="discover" onTabChange={setActiveTab} className="md:hidden" />

                <h2 className="font-medium m-0">Discover</h2>

                <div className="flex justify-between gap-5">
                    <div className="w-8 h-8 cursor-pointer">
                        <img src={FilterIcon} alt="Filter icon" />
                    </div>
                    <Search />
                </div>

                <div className="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 gap-5 lg:gap-10 pb-5 lg:pb-10">
                    {blueprintsData.map((blueprint) => (
                        <BlueprintModelCard
                            key={blueprint.id}
                            blueprintData={blueprint}
                            buttonText="Add"
                            onClick={handleClick}
                            type="discover"
                        />
                    ))}
                </div>
            </div>
        </VerticalScroll>
    );
};
