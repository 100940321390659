import { Field, useFormikContext } from "formik";
import { Slider } from "@mantine/core";
import { FormOptions } from "src/modules/waitlist-from";
import { formData } from "src/modules/waitlist-from/data";

interface ThirdStepValues {
    businessLevel: string;
    challenges: string;
    timeSpent: number;
}

export const ThirdStep = () => {
    const { values, setFieldValue, errors, touched, setFieldTouched } = useFormikContext<ThirdStepValues>();

    return (
        <>
            <div className="flex flex-col gap-5">
                <span className="text-xl">How would you describe your business's growth level?</span>
                <FormOptions fieldName="businessLevel" options={formData.businessLevels} />

                {touched.businessLevel && errors.businessLevel && (
                    <div className="text-red-500 text-sm">{errors.businessLevel}</div>
                )}
            </div>

            <div className="flex flex-col gap-5">
                <span className="text-xl">What's your biggest challenge with your current setup?</span>
                <FormOptions fieldName="challenges" options={formData.challenges} />

                {touched.challenges && errors.challenges && (
                    <div className="text-red-500 text-sm">{errors.challenges}</div>
                )}

                {values.challenges === "Other" && (
                    <Field
                        as="textarea"
                        name="otherChallenge"
                        placeholder="Please specify your challenge in few words..."
                        className="resize-none p-3 flex-grow bg-inherit rounded-md border-black placeholder:text-gray-400 placeholder:italic placeholder:text-sm"
                        onFocus={() => setFieldTouched('otherChallenge', true)}
                    />
                )}
            </div>

            <div className="flex flex-col gap-10 flex-grow">
                <span className="text-xl">How much time do you spend weekly on POD/e-commerce tasks?</span>
                <Slider
                    value={values.timeSpent}
                    onChange={(value) => setFieldValue('timeSpent', value)}
                    p={10}
                    min={0}
                    max={20}
                    label={`${values.timeSpent} Hours`}
                    labelAlwaysOn
                    color="black"
                    size={2}
                    thumbSize={14}
                    styles={{
                        thumb: { borderWidth: 3, backgroundColor: "black" },
                        label: {
                            transform: "translateY(180%)",
                            background: "none",
                            color: "black",
                            fontSize: 14
                        }
                    }}
                />
            </div>
        </>
    );
};
