import { FC, PropsWithChildren, useState } from "react";
import { BlueprintDetailedInfo } from "src/modules/blueprints/components";
import { Blueprint } from "src/models/blueprint";

interface BlueprintModelCardProps {
    blueprintData: Blueprint;
    buttonText: string;
    onClick: () => void;
    type: "discover" | "collections";
}

export const BlueprintModelCard: FC<BlueprintModelCardProps & PropsWithChildren> = (
    { blueprintData, buttonText, onClick, type }
) => {
    const [isExpanded, setIsExpanded] = useState(false);
    const [loadingStates, setLoadingStates] = useState({
        mainImage: true,
        secondaryImage1: true,
        secondaryImage2: true
    });

    const handleImageLoad = (imageKey: keyof typeof loadingStates) => {
        setLoadingStates(prev => ({
            ...prev,
            [imageKey]: false
        }));
    };

    const displayMoreInfo = () => {
        setIsExpanded(!isExpanded);
    };

    const hideDetails = () => {
        isExpanded && setIsExpanded(false);
    };

    return (
        <div
            className={`flex flex-col justify-between gap-3 transition-all duration-300 ${isExpanded ? "md:row-span-2 md:col-span-2" : ""}`}
            onClick={hideDetails}
        >
            <div className="flex justify-between items-center gap-2 flex-grow">
                <h3 className="text-lg font-medium m-0">{blueprintData.title}</h3>

                {!isExpanded &&
                    <button onClick={onClick} className="bg-transparent outline-none border-none text-gray-400 cursor-pointer">
                        {buttonText}
                    </button>
                }
            </div>

            <div className={`flex min-h-[200px] ${isExpanded ? "gap-4 md:gap-8" : "gap-4"}`}>
                <div className={`relative w-[65%] h-full ${loadingStates.mainImage ? 'bg-gray-200' : ''} rounded-sm`}>
                    {loadingStates.mainImage && (
                        <div className="h-full w-full bg-gray-300 animate-pulse" />
                    )}

                    <img
                        src={blueprintData.images[0]}
                        className={`w-full h-full object-cover rounded-sm transition-opacity duration-200 ${loadingStates.mainImage ? 'opacity-0' : 'opacity-100'}`}
                        onLoad={() => handleImageLoad('mainImage')}
                        alt={`${blueprintData.title} main view`}
                    />
                </div>

                <div className={`w-[30%] flex flex-col ${isExpanded ? "gap-2 md:gap-4" : "gap-2"}`}>
                    <div className={`relative h-1/2 ${loadingStates.secondaryImage1 ? 'bg-gray-200' : ''} rounded-sm`}>
                        {loadingStates.secondaryImage1 && (
                            <div className="h-full w-full bg-gray-300 animate-pulse" />
                        )}
                        <img
                            src={blueprintData.images[1]}
                            className={`w-full h-full object-cover rounded-sm transition-opacity duration-200 ${loadingStates.secondaryImage1 ? 'opacity-0' : 'opacity-100'}`}
                            onLoad={() => handleImageLoad('secondaryImage1')}
                            alt={`${blueprintData.title} secondary view 1`}
                        />
                    </div>

                    <div className={`relative h-1/2 ${loadingStates.secondaryImage2 ? 'bg-gray-200' : ''} rounded-sm`}>
                        {loadingStates.secondaryImage2 && (
                            <div className="h-full w-full bg-gray-300 animate-pulse" />
                        )}
                        <img
                            src={blueprintData.images[2]}
                            className={`w-full h-full object-cover rounded-sm transition-opacity duration-200 ${loadingStates.secondaryImage2 ? 'opacity-0' : 'opacity-100'}`}
                            onLoad={() => handleImageLoad('secondaryImage2')}
                            alt={`${blueprintData.title} secondary view 2`}
                        />
                    </div>
                </div>
            </div>

            {isExpanded && <BlueprintDetailedInfo />}

            {type === "discover" && !isExpanded &&
                <div className="flex justify-between text-gray-400">
                    <span>12.99$</span>
                    <button
                        onClick={displayMoreInfo}
                        className="bg-transparent outline-none border-none text-gray-400 cursor-pointer"
                    >
                        See more
                    </button>
                </div>
            }
        </div>
    );
};
