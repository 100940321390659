import { FC, memo, useRef } from "react";
import { Box } from "@mantine/core";
import gsap from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { useGSAP } from "@gsap/react";

import { InitialSlide } from "./slides/initial-slide";
import { FirstSlide } from "./slides/first-slide";
import { SecondSlide } from "./slides/second-slide";
import { ThirdSlide } from "./slides/third-slide";
import { FourthSlide } from "./slides/fourth-slide";

gsap.registerPlugin(useGSAP);
gsap.registerPlugin(ScrollTrigger);

export const SecondScreen: FC = memo(() => {
  const wrapper = useRef<HTMLDivElement | null>(null);
  const slider = useRef<HTMLDivElement | null>(null);

  useGSAP(
    () => {
      let slides = gsap.utils.toArray(".slide");

      gsap.to(slides, {
        xPercent: -100 * (slides.length - 1),
        ease: "none",
        scrollTrigger: {
          trigger: slider.current,
          pin: true,
          scrub: 1, // Adjust the scrub for smoother control
          snap: {
            snapTo: 1 / (slides.length - 1), // Snap to each slide
            duration: { min: 0.2, max: 0.5 }, // Control the snap duration
            delay: 0.1, // Delay before snapping to the next slide
            ease: "power1.inOut", // Easing for the snap effect
          },
          start: "top top",
          end: () => "+=" + slider.current?.offsetWidth,
        },
      });
    },
    { scope: wrapper }
  );

  return (
    <Box ref={wrapper} style={{ overflowX: "hidden" }}>
      <Box
        ref={slider}
        h="100vh"
        w="500vw"
        pt={65}
        display="flex"
        fw="nowrap"
        style={{ overflow: "hidden" }}
      >
        <InitialSlide />
        <FirstSlide />
        <SecondSlide />
        <ThirdSlide />
        <FourthSlide />
      </Box>
    </Box>
  );
});
