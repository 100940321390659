import { FC, PropsWithChildren, createContext, memo, useContext, useEffect, useMemo, useRef, useState } from "react";
import { WebSocketContextType } from "./types/context-type";
import { WebSocketClient } from "./web-socket-client";

const initialValue: WebSocketContextType = {
  websocketClient: {} as any,
  isConnected: false,
  messages: [],
  setMessages: (messages: any[]) => { },
};

const Context = createContext<WebSocketContextType>(initialValue);

export const WebSocketContext: FC<PropsWithChildren<{}>> = memo(({ children }) => {
  const ref = useRef<WebSocketClient>();
  const [isConnected, setIsConnected] = useState(false);
  const [messages, setMessages] = useState<any[]>([]);

  useEffect(() => {
    const instance = new WebSocketClient();
    ref.current = instance;

    const onOpen = (e: Event) => {
      setIsConnected(true);
    };

    const onMessage = (e: MessageEvent) => {
      try {
        const parsedData = JSON.parse(e.data);
        // Check if the message is not a ping response
        if (parsedData.action !== 'ping' && parsedData.message !== "Endpoint request timed out") {
          console.log("New message: ", parsedData);
          setMessages(prev => [...prev, parsedData]);
        }
      } catch (error) {
        console.error('Error parsing WebSocket message:', error);
      }
    };

    const onClose = (e: CloseEvent) => {
      setIsConnected(false);
      // Optionally attempt to reconnect
      setTimeout(() => {
        if (ref.current) {
          ref.current = new WebSocketClient();
        }
      }, 3000);
    };

    const onError = (e: Event) => {
      console.error('WebSocket error:', e);
      setIsConnected(false);
    };

    instance.webSocketInstance.addEventListener('open', onOpen);
    instance.webSocketInstance.addEventListener('message', onMessage);
    instance.webSocketInstance.addEventListener('close', onClose);
    instance.webSocketInstance.addEventListener('error', onError);

    return () => {
      if (instance.webSocketInstance) {
        instance.webSocketInstance.close();
        instance.webSocketInstance.removeEventListener('open', onOpen);
        instance.webSocketInstance.removeEventListener('message', onMessage);
        instance.webSocketInstance.removeEventListener('close', onClose);
        instance.webSocketInstance.removeEventListener('error', onError);
      }
    };
  }, []);

  const contextValue = useMemo(() => ({
    websocketClient: ref.current as WebSocketClient,
    isConnected,
    messages,
    setMessages
  }), [isConnected, messages]);

  return (
    <Context.Provider value={contextValue}>
      {children}
    </Context.Provider>
  );
});

export const useWebsocketClient = () => useContext(Context);