import { FC, Fragment, memo, PropsWithChildren, useEffect, useState } from "react";
import { AnimatedCardWrapper } from "src/modules/products-screens/products-list/components/animated-card-wrapper";
import { ProductDataType } from "src/models/product";

interface ProductCardProps {
  productData: ProductDataType;
  animated?: boolean;
}

export const ProductCard: FC<ProductCardProps & PropsWithChildren> = memo(
  ({ productData, animated = true, children }) => {
    const [isLoading, setIsLoading] = useState(true);

    // Get the image source with fallback
    const imageSource = productData.product_images?.[0];

    useEffect(() => {
      if (!imageSource) {
        setIsLoading(false);
        return;
      }

      const img = new Image();
      img.src = imageSource;
      img.onload = () => setIsLoading(false);
      img.onerror = () => setIsLoading(false);

      return () => {
        img.onload = null;
        img.onerror = null;
      };
    }, [imageSource]);

    const Wrapper = animated ? AnimatedCardWrapper : Fragment;

    return (
      <Wrapper>
        <div className="relative w-full pb-[100%] rounded-lg overflow-hidden">
          <div className="absolute inset-0">
            {isLoading && (
              <div className="h-full w-full bg-gray-300 animate-pulse" />
            )}

            {imageSource
              ? <img
                src={imageSource}
                alt={productData.product_name}
                draggable={false}
                className="h-full w-full object-cover"
              />
              : (
                <div
                  className="h-full w-full p-1 select-none bg-gray-200 text-gray-600 flex justify-center items-center text-center text-sm font-medium"
                >
                  Product without image
                </div>
              )
            }
          </div>
        </div>
        {children}
      </Wrapper>
    );
  }
);
