import { useQuery } from "@tanstack/react-query";
import { Field, useFormikContext } from "formik";
import { Select } from "@mantine/core";
import { FormInputField } from "src/modules/waitlist-from";
import { getCountriesList } from "src/services/automation.service";
import { formData } from "src/modules/waitlist-from/data";

interface FirstStepValues {
    name: string,
    subname: string,
    email: string,
    websiteLink: string,
    socialMediaLink: string,
    country: string;
    notificationsEnabled: boolean;
}

export const FirstStep = () => {
    const { values, setFieldValue, errors, touched } = useFormikContext<FirstStepValues>();

    const { data: countriesList } = useQuery({
        queryKey: ['waitlist-countries'],
        queryFn: async () => {
            return (await getCountriesList()).data;
        },
    });

    return (
        <>
            <span className="text-xl">Contact information</span>

            <div className="flex flex-wrap gap-5 md:gap-10">
                {formData.contactInfo.map(({ label, name, placeholder }) => (
                    <FormInputField
                        key={name}
                        label={label}
                        name={name}
                        placeholder={placeholder}
                    />
                ))}
            </div>

            <div className="flex flex-col gap-2">
                <Select
                    searchable
                    label="Country"
                    flex="1 0 auto"
                    placeholder="Country"
                    data={countriesList || []}
                    value={values.country}
                    onChange={(value) => setFieldValue('country', value || '')}
                    w={256}
                    styles={{
                        input: { borderColor: "black" },
                        label: { fontWeight: 400 },
                    }}
                />
                {touched.country && errors.country && (
                    <div className="text-red-500 text-sm">{errors.country}</div>
                )}
            </div>

            <div className="flex-grow">
                <div className="flex gap-2 items-center">
                    <Field
                        type="checkbox"
                        id="notificationsEnabled"
                        name="notificationsEnabled"
                        checked={values.notificationsEnabled}
                    />
                    <label
                        htmlFor="notificationsEnabled"
                        className="select-none text-sm cursor-pointer"
                    >
                        I want to be notified for updates about magic
                    </label>
                </div>
            </div>
        </>
    );
}