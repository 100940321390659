import { FC } from "react";
import { useField } from "formik";

interface FormOptionsProps {
    options: string[];
    fieldName: string;
    multiple?: boolean;
}

export const FormOptions: FC<FormOptionsProps> = ({ options, fieldName, multiple }) => {
    const [field, meta, helpers] = useField(fieldName);

    const handleClick = (option: string) => {
        helpers.setTouched(true, true);

        if (multiple) {
            const values = Array.isArray(field.value) ? field.value : [];
            const newValue = values.includes(option)
                ? values.filter(v => v !== option)
                : [...values, option];
            helpers.setValue(newValue);
        } else {
            helpers.setValue(option === field.value ? "" : option);
        }
    };

    const isSelected = (option: string) => {
        if (multiple) {
            return Array.isArray(field.value) && field.value.includes(option);
        }
        return field.value === option;
    };

    return (
        <div className="flex flex-wrap gap-4 select-none">
            {options.map((option) => (
                <div
                    key={option}
                    onClick={() => handleClick(option)}
                    className={`text-center border border-solid rounded-md text-nowrap py-2 px-8 leading-none cursor-pointer transition-colors duration-100 ${isSelected(option)
                        ? "border-black text-black"
                        : "border-gray-400 text-gray-400 hover:text-black hover:border-black"
                        }`}
                >
                    {option}
                </div>
            )
            )}
        </div>
    );
};
