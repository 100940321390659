export class WebSocketClient {
  public webSocketInstance: WebSocket;
  private messageInterval: NodeJS.Timeout | null = null;

  constructor() {
    const wsUrl = process.env.REACT_APP_API_WS_SERVER_URL as string;
    if (!wsUrl) {
      throw new Error('WebSocket URL is not defined');
    }
    
    this.webSocketInstance = new WebSocket(wsUrl);
    
    this.webSocketInstance.onopen = () => {
      console.log('WebSocket connected');
      this.sendMessage({ action: "RecieveMessage" });
      
      // Start sending messages every 500ms
      this.messageInterval = setInterval(() => {
        this.sendMessage({ action: "RecieveMessage" });
      }, 500);
    };

    this.webSocketInstance.onerror = (error) => {
      console.error('WebSocket error:', error);
    };

    this.webSocketInstance.onclose = () => {
      console.log('WebSocket closed');
      // Clear the interval when the connection closes
      this.clearMessageInterval();
    };
  }

  private clearMessageInterval(): void {
    if (this.messageInterval) {
      clearInterval(this.messageInterval);
      this.messageInterval = null;
    }
  }

  public sendMessage(message: any): void {
    if (this.webSocketInstance.readyState === WebSocket.OPEN) {
      this.webSocketInstance.send(JSON.stringify(message));
    } else {
      console.error('WebSocket is not connected');
    }
  }

  public disconnect(): void {
    // Clear the interval before disconnecting
    this.clearMessageInterval();
    
    if (this.webSocketInstance) {
      this.webSocketInstance.close();
    }
  }
}