import { FC, useEffect, useState } from "react";
import { Formik } from "formik";
import { useMediaQuery } from "@mantine/hooks";
import { Drawer } from "@mantine/core";
import { FirstStep, FormStepper, SecondStep, ThirdStep, FourthStep, StartScreen, FinalScreen } from "src/modules/waitlist-from";
import { MenuCloseIcon } from "src/modules/products-screens/components/menu/components/menu-close-icon";
import { saveJoinWaitlistData } from "src/services/automation.service";
import { initialWaitlistFormValues, validationWaitlistFormSchemas } from "src/modules/waitlist-from/utils";

import logoImg from 'src/assets/icons/logo-black.png';

interface JoinWaitlistModalProps {
    opened: boolean;
    onClose: () => void;
}

export const JoinWaitlistModal: FC<JoinWaitlistModalProps> = ({ opened, onClose }) => {
    const isMobile = useMediaQuery('(max-width: 768px)');

    const [step, setStep] = useState(0);
    const stepsQuantity = 5;

    const stepScreens: (() => JSX.Element)[] = [
        StartScreen,
        FirstStep,
        SecondStep,
        ThirdStep,
        FourthStep,
        FinalScreen,
    ];

    const StepElement = stepScreens[step];

    const closeModal = () => {
        onClose();
        setStep(0);
    };

    const handleSubmit = async (values: typeof initialWaitlistFormValues, { setSubmitting }: any) => {
        try {
            await saveJoinWaitlistData(values);

            console.log('Form data:', values);
        } catch (error) {
            console.error('Form submission error:', error);
        } finally {
            setSubmitting(false);
        }
    };

    useEffect(() => {
        if (step === 0 && opened) {
            setTimeout(() => setStep(1), 5000);
        }
    }, [opened]);

    return (
        <Drawer
            position="right"
            opened={opened}
            onClose={closeModal}
            returnFocus={false}
            withCloseButton={false}
            size={isMobile ? "100vw" : "60vw"}
            overlayProps={{ backgroundOpacity: 0, blur: 0 }}
            withOverlay={false}
            bg="none"
            styles={{
                content: {
                    background: "none",
                    boxShadow: "none"
                },
                body: {
                    height: "100%",
                    display: "flex",
                    flexDirection: "column",
                    padding: 0,
                },
            }}
        >
            <div
                className="flex items-center bg-[#fefcf0] justify-end h-[65px] px-10 sm:px-14 lg:px-[100px] flex-none"
                style={{ borderBottom: "1px solid black", marginLeft: isMobile ? 0 : 20 }}
            >
                <div className="fixed right-[50vw] translate-x-1/2 flex justify-center items-center">
                    <img
                        src={logoImg}
                        alt="logo"
                        className="w-16 sm:w-20 mx-auto"
                    />
                </div>

                <span className="hidden sm:inline text-lg sm:text-xl md:text-2xl lg:text-3xl font-medium text-zinc-400">Joining the Waitlist</span>
            </div>

            <Formik
                initialValues={initialWaitlistFormValues}
                validationSchema={validationWaitlistFormSchemas[step - 1]}
                onSubmit={handleSubmit}
            >
                {() => (
                    <div
                        className={`
                       bg-[#fefcf0] relative flex-grow flex flex-col px-10 pt-10 pb-5 md:pb-10 gap-5 md:gap-10 h-full justify-between overflow-auto
                       ${step === 0 || step === stepsQuantity ? "md:px-20 md:pt-20" : "md:px-16 md:pt-16"}`}
                        style={{ marginLeft: isMobile ? 0 : 20 }}
                    >
                        <div
                            onClick={closeModal}
                            className="absolute top-5 right-5 self-end cursor-pointer"
                            style={{ display: isMobile ? "block" : "none" }}
                        >
                            <MenuCloseIcon opened />
                        </div>

                        <StepElement />

                        {step !== 0 && step !== stepsQuantity && <FormStepper activeStep={step} setStep={setStep} stepsQuantity={stepsQuantity} />}
                    </div>
                )}
            </Formik>

            {!isMobile &&
                <div className="h-screen w-[1px] bg-black absolute left-5 top-0">
                    <div
                        onClick={closeModal}
                        className="absolute top-1/2 translate-y-1/2 -translate-x-1/2 left-0 h-9 w-9 flex justify-center items-center bg-[#fefcf0] cursor-pointer border border-solid border-black rounded-lg z-10"
                    >
                        <MenuCloseIcon opened />
                    </div>
                </div>
            }
        </Drawer>
    );
};
