import { FC, useEffect, useState, useRef, ReactNode } from 'react';
import { SQSPersonaMessage } from 'src/models/persona';
import { SQSProductMessage } from 'src/models/product';
import { AnimatedText } from 'src/modules/landing-screens';

interface CreationProcessProps {
    header?: ReactNode;
    currentProductCount?: number;
    creationMessages: SQSPersonaMessage[] | SQSProductMessage[];
    stepsQuantity: number;
  }
  
  export const CreationProcess: FC<CreationProcessProps> = ({
    header,
    currentProductCount,
    creationMessages,
    stepsQuantity,
  }) => {
    const [displayProgress, setDisplayProgress] = useState(0);
    const [currentMessage, setCurrentMessage] = useState<SQSPersonaMessage | SQSProductMessage | null>(null);
    const [isAnimating, setIsAnimating] = useState(false);
    const previousProgress = useRef(0);

    const resetState = () => {
        setDisplayProgress(0);
        setCurrentMessage(null);
        setIsAnimating(false);
        previousProgress.current = 0;
    };

    useEffect(() => {
        resetState();
    }, [currentProductCount]);

    const isPersonaMessage = (
        message: SQSPersonaMessage | SQSProductMessage | null
    ): message is SQSPersonaMessage => {
        return message !== null && 'description' in message;
    };

    useEffect(() => {
        if (creationMessages.length === 0) return;
        const newMessage = creationMessages.at(-1);
        newMessage && setCurrentMessage(newMessage);
    }, [creationMessages.length]);

    useEffect(() => {
        if (creationMessages.length === 0) return;

        const currentStep = creationMessages.length - 1;
        const targetProgress = Math.round((currentStep / (stepsQuantity - 1)) * 100);
        let startTime: number;
        setIsAnimating(true);

        const animate = (currentTime: number) => {
            if (!startTime) startTime = currentTime;
            const elapsed = currentTime - startTime;
            const duration = 4000;

            if (elapsed < duration) {
                const progress = elapsed / duration;
                // const eased = 1 - Math.pow(1 - progress, 3);
                const progressValue = Math.round(
                    previousProgress.current + (targetProgress - previousProgress.current) * progress
                );
                setDisplayProgress(progressValue);
                requestAnimationFrame(animate);
            } else {
                setDisplayProgress(targetProgress);
                previousProgress.current = targetProgress;
                setIsAnimating(false);
            }
        };

        requestAnimationFrame(animate);
    }, [creationMessages.length]);

    if (!currentMessage) return null;

    return (
        <div className="h-full w-full flex flex-col gap-5">
            {header && header}

            <div className="flex-grow grid grid-cols-2 gap-6 h-full w-full">
                {Array.from({ length: 4 }).map((_, index) => (
                    <div
                        key={index}
                        className="bg-gray-300 rounded-sm animate-pulse h-full w-full"
                    />
                ))}
            </div>

            <div className="flex justify-between gap-3">
                <div className='max-h-[25vh] overflow-auto'>
                    <AnimatedText
                        textContent={isPersonaMessage(currentMessage)
                            ? currentMessage.description
                            : currentMessage.message
                        }
                        speed={2}
                        className="italic"
                        fromColor="gray"
                        toColor="gray"
                        textSize={16}
                    />
                </div>
                <span className="text-gray-400 text-xs">
                    {displayProgress}%
                </span>
            </div>

            <div className="flex flex-col gap-4">
                <AnimatedText textContent={currentMessage?.title} speed={2} />

                <div className="relative w-full h-3">
                    <div className="absolute top-1/2 left-0 right-0 h-0.5 bg-gray-200" />

                    <div
                        className={`absolute top-1/2 left-0 h-0.5 bg-black ${isAnimating ? 'transition-none' : 'transition-all duration-300 ease-out'}`}
                        style={{
                            width: `${displayProgress}%`,
                            transform: 'translateZ(0)',
                        }}
                    />

                    <div className="absolute top-1/2 left-0 right-0 flex justify-between">
                        {Array.from({ length: stepsQuantity }).map((_, index) => {
                            const dotPosition = (index / (stepsQuantity - 1)) * 100;
                            const isFilled = displayProgress >= dotPosition;
                            const isActive = creationMessages.length - 1 === index;

                            return (
                                <div
                                    key={index}
                                    className="relative w-3 h-3 transform -translate-y-1/2"
                                >
                                    <div
                                        className={`absolute top-0 left-0 w-full h-full rounded-full transition-colors duration-300 
                                        ${isFilled ? 'bg-black' : 'bg-gray-200'}
                                        ${isActive ? "animate-growPulse" : ""}`}
                                    />
                                </div>
                            );
                        })}
                    </div>
                </div>
            </div>
        </div>
    );
};
