import { memo, useRef } from "react";
import { Box, Divider } from "@mantine/core";
import { BenefitsBlock } from "../benefits-block";
import { SlideNumber } from "../slide-number";
import FifthImage1 from "../../../../../assets/Landing/FifthPage-1.avif";
import FifthImage2 from "../../../../../assets/Landing/FifthPage-2.avif";
import FifthImage3 from "../../../../../assets/Landing/FifthPage-3.avif";
import FifthImage4 from "../../../../../assets/Landing/FifthPage-4.avif";
import FifthImage5 from "../../../../../assets/Landing/FifthPage-5.avif";
import { motion, Variants } from "framer-motion";

export const ThirdSlide = memo(() => {
  const containerRef = useRef<HTMLDivElement | null>(null);
  const gridAreas = [
    { position: "first", image: FifthImage2, id: "first-main" },
    { position: "second", image: FifthImage3, id: "second-main" },
    { position: "third", image: FifthImage4, id: "third-main" },
    { position: "fourth", image: FifthImage5, id: "fourth-main" },
  ];
  const gridItemVariants: Variants = {
    hidden: (index: number) => ({
      opacity: 0.5,
      scale: 1.08,
    }),
    visible: {
      opacity: 1,
      scale: 1,
      transition: {
        duration: 1.2,
        ease: "easeOut",
      },
    },
  };

  const lastItemVariants: Variants = {
    hidden: {
      opacity: 0,
      x: 100,
    },
    visible: {
      opacity: 1,
      x: 0,
      transition: {
        duration: 0.4,
        ease: "easeOut",
      },
    },
  };

  return (
    <Box className="slide" w="100vw" h="100%" display="flex">
      <BenefitsBlock
        image={FifthImage1}
        heading="Scale Your Business Without Limits"
        description="Every order is handled seamlessly, and with real-time performance tracking, you can adjust your strategy and maximize sales. Built to stay on top of trends and offer limitless product capacity without stock, M-AGI-C enables you to expand your business without the traditional constraints."
      />

      <Divider color="black" orientation="vertical" />

      <div className="w-3/5 flex-none hidden md:block p-5 lg:p-10">
        <Box
          ref={containerRef}
          w="100%"
          h="90%"
          display="grid"
          style={{
            gap: 25,
            gridTemplateColumns: "repeat(4, 1fr)",
            gridTemplateRows: "repeat(2, 1fr)",
            gridTemplateAreas: `
            "second third first first"
            "fourth fourth first first"
          `,
          }}
        >
          {gridAreas.map((gridArea, i) => (
            <motion.div
              key={gridArea.id}
              custom={i}
              variants={gridItemVariants}
              initial="hidden"
              whileInView="visible"
              viewport={{ once: true, margin: "-20% 0px" }}
              style={{
                gridArea: gridArea.position,
                backgroundImage: `url(${gridArea.image})`,
                backgroundSize: "cover",
                backgroundPosition: "center",
                backgroundRepeat: "no-repeat",
                border: "2px solid grey",
              }}
            />
          ))}
        </Box>
      </div>

      <Divider color="black" orientation="vertical" />

      <SlideNumber slideNumber={3} side="right" />
    </Box>
  );
});
