import { FC, memo, useRef } from 'react';
import { useLocation } from 'react-router-dom';
import gsap from 'gsap';
import { useGSAP } from '@gsap/react';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import { Burger } from '@mantine/core';
import { AnimatedText } from 'src/modules/landing-screens/components';
import { useHeaderTitle } from 'src/modules/header/components/header-title';
import logoImg from 'src/assets/icons/logo-black.png';

interface HeaderProps {
  opened?: boolean;
  toggle?: () => void;
  title?: string;
  paddingLeft?: number;
  navigationElem?: JSX.Element | null;
  withAnimation?: boolean;
}

gsap.registerPlugin(useGSAP);
gsap.registerPlugin(ScrollTrigger);

export const Header: FC<HeaderProps> = memo(
  ({ opened, toggle, title, paddingLeft, navigationElem, withAnimation = true }) => {
    const container = useRef<HTMLDivElement | null>(null);
    const { pathname } = useLocation();
    const titleByRoute = useHeaderTitle(pathname);

    const tl = useRef<GSAPTimeline | null>(null);

    useGSAP(
      () => {
        if (!withAnimation) return;

        tl.current = gsap.timeline({
          delay: 0.5,
          defaults: {
            duration: 2,
          },
        });

        tl.current.fromTo(
          '#logo',
          { opacity: 0 },
          {
            opacity: 1,
          }
        );

        tl.current.fromTo(
          '#line',
          { width: 0 },
          {
            width: '100vw',
          },
          '-=1'
        );
      },
      { scope: container }
    );

    return (
      <div
        ref={container}
        className={`fixed w-full h-16 top-0 left-0 z-50 transition-padding duration-100`}
        style={{ paddingLeft }}
      >
        <div className="grid grid-cols-12 h-full w-full px-2.5 xs:px-10 sm:px-[5%] bg-[#fefcf0]">
          <div className="col-span-5 flex items-center">
            {withAnimation ? (
              <AnimatedText
                toColor="#ccc"
                delay={1}
                textContent={title || titleByRoute}
                textSize={38}
              />
            ) : (
              <span className="text-gray-800 leading-tight font-medium hidden min-[400px]:inline-block min-[575px]:pl-8 min-[640px]:pl-3 text-xl sm:text-3xl md:text-4xl">
                {title || titleByRoute}
              </span>
            )}
          </div>

          <div id="logo" className="col-span-2 flex justify-center items-center">
            <img
              src={logoImg}
              alt="logo"
              className="w-16 sm:w-20 mx-auto"
            />
          </div>

          <div className="col-span-5 text-right flex items-center justify-end">
            {navigationElem}
            {toggle &&
              <Burger opened={opened} onClick={toggle} className='inline-block px-4 min-[575px]:hidden z-[51]' />
            }
          </div>
        </div>

        <div
          id="line"
          style={{ borderBottom: "1px solid black" }}
        />
      </div>
    );
  }
);
