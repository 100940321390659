import { FC, memo } from "react";
import { Box, Drawer, Flex, Image, Text } from "@mantine/core";
import { useProducts } from "src/selected-products-provider";

interface ProductInfoProps {
  opened: boolean;
  close: () => void;
}

export const ProductInfo: FC<ProductInfoProps> = memo(
  ({ opened, close }) => {
    const { selectedProductData } = useProducts();

    if (!selectedProductData) return null;

    return (
      <Drawer
        size={650}
        padding="md"
        opened={opened}
        onClose={close}
        returnFocus={false}
        overlayProps={{ backgroundOpacity: 0, blur: 0 }}
        styles={{
          close: {
            height: 40,
            width: 40,
          },
          content: {
            borderRight: "1px solid black",
          },
        }}
      >
        <Flex direction="column" gap="lg">
          <Text size="xl" fw={700} mb="xs">
            {selectedProductData.product_name}
          </Text>

          {selectedProductData.product_images[0] && (
            <Box>
              <Image
                width="60%"
                height={300}
                fit="contain"
                radius="md"
                src={selectedProductData.product_images[0]}
                alt={selectedProductData.product_name}
              />
            </Box>
          )}

          <Box>
            <Flex fw={500} direction="column" gap="xs" mb="md">
              {selectedProductData.product_id && <span>Product ID: {selectedProductData.product_id}</span>}
              <span>Category: {selectedProductData.category}</span>
              <span>Theme: {selectedProductData.theme}</span>
              <span>Collection: {selectedProductData.collection_name}</span>
            </Flex>

            <div
              dangerouslySetInnerHTML={{ __html: selectedProductData.description }}
            />
          </Box>

          {selectedProductData?.blueprints?.length > 0 && (
            <Box mt="lg">
              <Text size="lg" fw={600} mb="md">
                Blueprints
              </Text>
              <Flex direction="column" gap="md">
                {selectedProductData.blueprints.map((blueprint) => (
                  <Box
                    key={blueprint.id}
                    p="md"
                    style={{ border: '1px solid #eee', borderRadius: '8px' }}
                  >
                    <Text fw={500} mb="xs">{blueprint.title}</Text>
                    <div
                      dangerouslySetInnerHTML={{ __html: blueprint.description }}
                    />
                  </Box>
                ))}
              </Flex>
            </Box>
          )}

          <Flex gap="md" wrap="wrap" fw={500} justify="center">
            <span>
              Created: {new Date(selectedProductData.created_at).toLocaleDateString()}
            </span>
            <span>
              Sync Status: {selectedProductData.is_synced ? 'Synced' : 'Not Synced'}
            </span>
            <span>
              Persona ID: {selectedProductData.persona_id}
            </span>
          </Flex>
        </Flex>
      </Drawer>
    );
  }
);
