import { FC, useEffect, useRef, useState, PropsWithChildren } from "react";
import { motion, useMotionValue, useTransform } from "framer-motion";

interface VerticalScrollProps {
  paddingSize?: number;
  className?: string;
}

export const VerticalScroll: FC<VerticalScrollProps & PropsWithChildren> = ({
  paddingSize = 80,
  className = "",
  children,
}) => {
  const [dragConstraints, setDragConstraints] = useState({ top: 0, bottom: 0 });

  const containerRef = useRef<HTMLDivElement>(null);
  const scrollRef = useRef<HTMLDivElement>(null);
  const y = useMotionValue(0);
  const thumbSize = 12;

  const calculateScrollPosition = () => {
    if (containerRef.current && scrollRef.current) {
      const scrollTrackHeight = scrollRef.current.clientHeight - paddingSize;
      const maxScroll = scrollTrackHeight - thumbSize;
      setDragConstraints({ top: 0, bottom: maxScroll });
    }
  };

  const containerScroll = useTransform(y, (latest) => {
    if (containerRef.current && scrollRef.current) {
      const scrollTrackHeight =
        scrollRef.current.clientHeight - paddingSize - thumbSize;
      const scrollableHeight =
        containerRef.current.scrollHeight - containerRef.current.clientHeight;
      const scrollPercentage = latest / scrollTrackHeight;

      return scrollPercentage * scrollableHeight;
    }
    return 0;
  });

  useEffect(() => {
    const unsubscribe = containerScroll.on("change", (latest) => {
      if (containerRef.current) {
        containerRef.current.scrollTop = latest;
      }
    });

    return () => unsubscribe();
  }, [containerScroll]);

  const handleScroll = () => {
    if (containerRef.current && scrollRef.current) {
      const container = containerRef.current;
      const scrollTrackHeight =
        scrollRef.current.clientHeight - paddingSize - thumbSize;
      const scrollableHeight = container.scrollHeight - container.clientHeight;
      const scrollPercentage = container.scrollTop / scrollableHeight;
      const newPosition = scrollPercentage * scrollTrackHeight;
      y.set(newPosition);
    }
  };

  useEffect(() => {
    if (!containerRef.current) return;

    containerRef.current.addEventListener("scroll", handleScroll);

    // Add resize observer to recalculate constraints when container size changes
    const resizeObserver = new ResizeObserver(() => {
      calculateScrollPosition();
      handleScroll();
    });

    resizeObserver.observe(containerRef.current);
    calculateScrollPosition();
    handleScroll();

    return () => {
      containerRef.current?.removeEventListener("scroll", handleScroll);
      resizeObserver.disconnect();
    };
  }, []);

  return (
    <div className={`relative h-full ${className}`}>
      <div
        ref={containerRef}
        className="w-full h-full overflow-y-auto overflow-x-hidden"
      >
        {children}
      </div>

      <div
        ref={scrollRef}
        className={`hidden md:block absolute top-0 right-0 h-full w-[1px] cursor-pointer bg-black py-[${paddingSize}px]`}
      >
        <div className="-translate-x-1/2 w-max">
          <motion.div
            className="h-3 w-3 bg-black rounded-full cursor-pointer"
            drag="y"
            dragConstraints={dragConstraints}
            dragElastic={0}
            dragMomentum={false}
            style={{ y }}
          />
        </div>
      </div>
    </div>
  );
};
