import { FC, memo } from 'react';
import { Flex, Select, Text } from '@mantine/core';
import { FormInput } from 'src/modules/store-info/components/form-input';
import { useStoreContext } from 'src/contexts';
import { StoreFullDataType } from 'src/modules/store-creator/types/store-data';

interface StoreInfoFormProps {
  editMode: boolean;
  countryData: string[];
}

export const StoreInfoForm: FC<StoreInfoFormProps> = memo(
  ({ editMode, countryData }) => {
    const { storeFullData, setStoreFullData } = useStoreContext();

    const handleInputChange = (
      field: keyof StoreFullDataType,
      value: string | number
    ) => {
      if (setStoreFullData) {
        setStoreFullData({
          ...storeFullData,
          products: storeFullData?.products || { product_count: 0, products_data: [] , published_product_count: 0},
          [field]: value,
        });
      } else {
        console.error('setStoreFullData is undefined');
      }
    };

    const country = storeFullData?.country || 'N/A';
    const storeName = storeFullData?.name || 'Store name';
    const keyNumber = storeFullData?.keynumber || 'N/A';

    return (
      <Flex gap="lg" className=" rounded-lg " justify="space-between">
        {/* Left Section */}
        <Flex direction="column" gap="sm" className="w-1/2">
          {editMode ? (
            <Select
              searchable
              placeholder="Select a country"
              defaultValue={country}
              data={countryData}
              className="w-full"
              onChange={(value) => handleInputChange('country', value || '')}
              error={!countryData.length && 'Country data not available'}
            />
          ) : (
            <Text size="md" w={500}>
              Country: <span className="text-gray-500">{country}</span>
            </Text>
          )}

          <FormInput
            editMode={editMode}
            label="Name of the store"
            placeholder={storeName}
            value={storeFullData?.name || ''}
            onChange={(value) => handleInputChange('name', value)}
          />

          <Text size="md" w={500}>
            Platform POD: <span className="text-gray-500">{storeFullData?.provider || "Not Connected"}</span>
          </Text>
        </Flex>

        {/* Right Section */}
        <Flex direction="column" gap="sm" className="w-1/2">
          <Text size="md" w={500}>
            Number of products:{' '}
            <span className="text-gray-500">
              {storeFullData?.products?.product_count || 0}
            </span>
          </Text>

          <FormInput
            editMode={false}
            label="Affiliate number"
            placeholder="No"
          />

          <FormInput
            editMode={false}
            label="Key number"
            placeholder={String(keyNumber)}
            value={String(storeFullData?.keynumber || '')}
            onChange={(value) => handleInputChange('keynumber', Number(value))}
          />
        </Flex>
      </Flex>
    );
  }
);
