import { memo, ReactNode, useEffect, useState } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { Box, Button, Drawer, Flex, Text } from '@mantine/core';
import { FilterIcon } from 'src/modules/filter/components/filter-icon';
import { useFilterContext } from 'src/contexts';
import { ApiResponse } from 'src/models/api-response';
import { Search } from 'src/modules/products-screens/components/search';
import { ArtworksFilter, PersonasFilter, ProductsFilter } from 'src/modules/filter/variants';
import { filterArtworks, filterPersonas, filterProducts } from 'src/services/automation.service';
import { initialPersonaFilters, initialArtworkFilters, initialProductFilters } from 'src/modules/filter/utils/initial-data';
import { FilterType, PersonaFiltersType, ArtworkFiltersType, FilterConfig, ProductFilterType } from 'src/modules/filter/types';
import { FILTER_CONFIGS, TransformFilterConfig } from '../config';

interface FilterProps<T> {
  opened: boolean;
  open: () => void;
  close: () => void;
  type: FilterType;
  setFilteredData: (data: T[]) => void;
}

type FilterDataType = PersonaFiltersType | ArtworkFiltersType | ProductFilterType;

type FilterDispatchMap = {
  personas: { type: "SET_PERSONA_FILTER"; payload: PersonaFiltersType };
  artworks: { type: "SET_ARTWORK_FILTER"; payload: ArtworkFiltersType };
  products: { type: "SET_PRODUCTS_FILTER"; payload: ProductFilterType };
};

const dispatchConfig = {
  personas: { type: "SET_PERSONA_FILTER" as const },
  artworks: { type: "SET_ARTWORK_FILTER" as const },
  products: { type: "SET_PRODUCTS_FILTER" as const },
};

const initialDataVariants: Record<FilterType, FilterDataType> = {
  personas: initialPersonaFilters,
  artworks: initialArtworkFilters,
  products: initialProductFilters,
}

const filterHandlers: Record<FilterType, (token: string, filterQuery: string) => Promise<ApiResponse>> = {
  personas: filterPersonas,
  artworks: filterArtworks,
  products: filterProducts,
};

function FilterComponent<T>({ opened, open, close, type, setFilteredData }: FilterProps<T>) {
  const { state: filters, dispatch } = useFilterContext();

  const [newFilters, setNewFilters] = useState<FilterDataType>(filters[type]);
  const [isFiltesrApplied, setIsFiltersApplied] = useState(false);

  const { getAccessTokenSilently } = useAuth0();

  useEffect(() => {
    dispatch({
      type: "SET_FILTER_TYPE",
      payload: type
    });
  }, [type, dispatch]);

  const clearFilters = () => {
    setNewFilters(initialDataVariants[type]);
  };

  function hasRequestValue(config: FilterConfig): config is TransformFilterConfig<any> {
    return config && 'requestValue' in config;
  }

  const getFilterQuery = (filterType: "new_filters" | "state_filters") => {
    const params = new URLSearchParams();
    const filterData = filterType === "new_filters" ? newFilters : filters[type];

    Object.entries(filterData).forEach(([key, value]) => {
      if (!value) return;

      const config = FILTER_CONFIGS[type].find((elem) => elem.key === key);
      if (!config) return;

      const paramValue = hasRequestValue(config) && config.requestValue ?
        config.requestValue(value).toString() :
        value.toString();

      if (key === 'store') {
      params.append('store_id', paramValue);
      }
      else {
        params.append(key, paramValue);
      }
    });

    return params.toString();
  };

  const fetchFilteredData = async (filterType: "new_filters" | "state_filters") => {
    const token = await getAccessTokenSilently();
    const filterQuery = getFilterQuery(filterType);
    const filterHandler = filterHandlers[type];

    const filteredData = (await filterHandler(token, filterQuery)).data || [];
    setFilteredData(filteredData as T[]);
  };

  const handleApply = async () => {
    close();

    const action: FilterDispatchMap[FilterType] = {
      type: dispatchConfig[type].type,
      payload: newFilters
    };

    dispatch(action);
    await fetchFilteredData("new_filters");
    setIsFiltersApplied(true)
  };

  const handleClose = () => {
    setNewFilters(filters[type]);
    close();
  };

  useEffect(() => {
    isFiltesrApplied && fetchFilteredData("state_filters");
  }, [JSON.stringify(filters[type])]);

  useEffect(() => {
    setNewFilters(filters[type]);
  }, [filters[type]]);

  const filterVariants: Record<FilterType, ReactNode> = {
    artworks: <ArtworksFilter newFilters={newFilters} setNewFilters={setNewFilters} />,
    personas: <PersonasFilter newFilters={newFilters} setNewFilters={setNewFilters} />,
    products: <ProductsFilter newFilters={newFilters} setNewFilters={setNewFilters} />
  };

  const FilterVariant = filterVariants[type];

  return (
    <>
      <Flex display="flex" justify="space-between" align="center" gap={10} w="100%" style={{ cursor: "pointer" }}>
        <Box onClick={open} w={30} h={30}>
          <FilterIcon />
        </Box>
        <Search />
      </Flex>

      <Drawer
        padding={30}
        size="xs"
        opened={opened}
        onClose={handleClose}
        title={`${type.charAt(0).toUpperCase() + type.slice(1)} Filters`}
        overlayProps={{ bg: "none" }}
        styles={{
          title: { fontSize: 26, fontWeight: 600 },
          close: { height: 40, width: 40 },
          content: { borderRight: '1px solid black' },
          header: { paddingBottom: 20 },
          body: { minHeight: '85%' }
        }}
      >
        <Flex direction="column" justify="space-between" h="100%" gap={20}>
          <Flex direction="column" gap={15}>
            <Flex justify="end">
              <Text onClick={clearFilters} style={{ cursor: 'pointer', color: 'gray' }}>
                Clear filters
              </Text>
            </Flex>

            {FilterVariant}

          </Flex>
          <Button
            variant="outline"
            w="max-content"
            onClick={handleApply}
            style={{ alignSelf: 'center' }}
          >
            Apply
          </Button>
        </Flex>
      </Drawer>
    </>
  );
}

export const Filter = memo(FilterComponent) as <T>(props: FilterProps<T>) => JSX.Element;
