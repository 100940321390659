import { FC, memo, useMemo, useState } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import {
  Button,
  Flex,
  Text,
  Group,
  Radio,
  Select,
  Checkbox,
  Modal,
} from "@mantine/core";
import { HorizontalCardsGrid } from "src/components/grids/horizontal-cards-grid";
import { generateBlueprints, saveStore } from "src/services/automation.service";
import { Store } from "@/models/store";
import { Supplier } from "@/models/supplier";
import { ProductTypes } from "src/models/product-types";
import { Blueprint } from "@/models/blueprint";
import { useStoreContext } from "src/contexts";

interface GenerateBlueprintsViewProps {
  selectedSupplier: Supplier | null;
  selectedStore: Store | null;
  setSelectedStore: (selectedStore: Store | null) => void;
  selectedBlueprintIds: number[];
  setSelectedBlueprintIds: (selectedBlueprintIds: number[]) => void;
}

export const GenerateBlueprintsView: FC<GenerateBlueprintsViewProps> = memo(
  ({
    selectedSupplier,
    selectedStore,
    setSelectedStore,
    selectedBlueprintIds,
    setSelectedBlueprintIds,
  }) => {
    const { stores } = useStoreContext();
    const [isStoreSaved, setIsStoreSaved] = useState(false);
    const [selectedProductType, setSelectedProductType] =
      useState<ProductTypes>("cup");
    const [blueprints, setBlueprints] = useState<Blueprint[]>([]);

    const { getAccessTokenSilently } = useAuth0();

    const productOptions: ProductTypes[] = [
      "cup",
      "tshirts",
      "blanket",
      "bag",
      "sneakers",
    ];

    // const storesQuery = useQuery({
    //   queryKey: ["stores"],
    //   queryFn: async () => {
    //     return getUserStores(await getAccessTokenSilently());
    //   },
    // });

    const transformToStore = (data: any): Store => {
      return {
        id: data.id,
        title: data.title,
        sales_channel: data.sales_channel,
      };
    };

    // const stores = useMemo(() => {
    //   if (storesQuery.isSuccess) {
    //     return storesQuery?.data?.data?.map((store: any) =>
    //       Object.fromEntries(
    //         Object.entries(store).filter(
    //           ([key]) => !key.startsWith("__") && !key.startsWith("_")
    //         )
    //       )
    //     );
    //   }

    //   return [];
    // }, [storesQuery?.data, storesQuery.isSuccess]);

    const handleSelectStore = async (storeName: string) => {
      if (!storeName) return setSelectedStore(null);

      const newlySelectedStore = transformToStore(
        stores.filter(
          (store: any) => store.title && storeName === store.title
        )[0]
      );

      setSelectedStore(newlySelectedStore);
    };

    const saveSelectedStore = async () => {
      if (!selectedStore || !selectedSupplier?.name) return;

      const accessToken = await getAccessTokenSilently();
      const data = {
        store_id: selectedStore.id,
        store_name: selectedStore.title,
        default_provider: selectedSupplier.name,
      };

      await saveStore(data, accessToken);

      setIsStoreSaved(true);
    };

    const handleProductTypeSelect = async (value: ProductTypes) => {
      const accessToken = await getAccessTokenSilently();

      setSelectedProductType(value);
      const blueprints = await generateBlueprints(
        { product_type: value },
        accessToken
      );

      setBlueprints(blueprints);
    };

    const handleBlueprintSelect = (blueprintId: number) => {
      if (selectedBlueprintIds.includes(blueprintId)) {
        const filteredBlueprints = selectedBlueprintIds.filter(
          (id) => id !== blueprintId
        );
        setSelectedBlueprintIds(filteredBlueprints);
      } else {
        if (selectedBlueprintIds.length === 5) return;

        setSelectedBlueprintIds([...selectedBlueprintIds, blueprintId]);
      }
    };

    if (!stores) {
      return (
        <Modal centered opened withCloseButton={false} onClose={() => null}>
          <Flex direction="column" gap={10} align="center" ta="center" fz={20}>
            No stores found. Please add a store on the supplier's webpage
            <Button component="a" href="/store-creator" fz={16}>
              Add a store
            </Button>
          </Flex>
        </Modal>
      );
    }

    return (
      <Flex direction="column" p={20} gap={20}>
        <Flex gap={30} align="end">
          {selectedSupplier && (
            <Select
              key={selectedSupplier.id}
              w="60%"
              miw={250}
              label="Select a Store"
              placeholder="Select a Store"
              searchable
              value={selectedStore?.title || null}
              onChange={(value) => handleSelectStore(value || "")}
              data={stores.map((store: any) => store.title)}
            />
          )}

          <Button
            w={200}
            onClick={saveSelectedStore}
            disabled={!selectedStore?.title}
          >
            Save the store
          </Button>
        </Flex>

        {isStoreSaved && (
          <Radio.Group
            value={selectedProductType}
            onChange={(value) => handleProductTypeSelect(value as ProductTypes)}
            name="product-type"
            label="Select a product type"
          >
            <Group mt="xs">
              {productOptions.map((option) => (
                <Radio key={option} value={option} label={option} />
              ))}
            </Group>
          </Radio.Group>
        )}

        {!!blueprints && (
          <Checkbox.Group>
            <HorizontalCardsGrid>
              {blueprints.map((blueprint) => (
                <Checkbox.Card
                  key={blueprint.id}
                  value={String(blueprint.blueprint_id)}
                  disabled={
                    !selectedBlueprintIds.includes(blueprint.blueprint_id) &&
                    selectedBlueprintIds.length === 5
                  }
                  pos="relative"
                  radius="md"
                  onClick={() => handleBlueprintSelect(blueprint.blueprint_id)}
                  w="35vh"
                  bg="#edefee"
                  style={{ userSelect: "none" }}
                >
                  <Checkbox.Indicator
                    pos="absolute"
                    right={5}
                    top={5}
                    size="md"
                    styles={{
                      icon: { cursor: "pointer" },
                      indicator: { cursor: "pointer" },
                    }}
                  />

                  {/* TODO: add a real blueprint data */}

                  {/* <Image
                    h="35vh"
                    w="35vh"
                    draggable={false}
                    src={blueprint.images[0]}
                    alt={blueprint.title}
                  /> */}

                  <Flex direction="column" gap={10} p={10} h="100%">
                    <Text fw={600}>{blueprint.title}</Text>
                    <Text>ID: {blueprint.id}</Text>
                    <Text
                      flex="1 0 auto"
                      style={{ overflow: "hidden", textOverflow: "ellipsis" }}
                    >
                      {blueprint.description}
                    </Text>
                  </Flex>
                </Checkbox.Card>
              ))}
            </HorizontalCardsGrid>
          </Checkbox.Group>
        )}
      </Flex>
    );
  }
);
