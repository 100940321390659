import { memo, useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Box, Button, Flex } from '@mantine/core';
import { StoreInfoWrapper, StoreInfoContent } from 'src/modules/store-info/components';
import { useStoreContext } from 'src/contexts';
import { mockedBlueprintColors } from 'src/modules/mocked-data/mocked-data';
import { ProductCard } from 'src/modules/products-screens/products-list';
import { BlueprintCard } from 'src/modules/blueprints';

export const StoreBlueprints = memo(() => {
  const { storeFullData, isLoading } = useStoreContext();
  const navigate = useNavigate();
  const [isProductViable, setIsProductViable] = useState(false);

  useEffect(() => {
    if (storeFullData?.persona?.persona_count) {
      setIsProductViable(storeFullData?.persona?.persona_count > 0);
    } else {
      setIsProductViable(false);
    }
    return () => { };
  }, [storeFullData, isLoading]);

  const editBlueprints = () => {
    navigate('/blueprints');
  };

  const addProducts = () => {
    navigate('/products');
  };

  const renderProductContent = () => {
    // If no products exist, show the Add button view
    if (storeFullData?.products?.product_count === 0) {
      return (
        <div className="grid h-full place-items-center">
          <div className="flex flex-col gap-2 items-center justify-center">
            <Button
              style={{ width: 200 }}
              variant="outline"
              color="black"
              size="sm"
              disabled={!isProductViable}
              onClick={addProducts}
            >
              Add Product
            </Button>
            {!isProductViable && <div>Please add a Persona first</div>}
          </div>
        </div>
      );
    }

    // If products exist, show the products grid
    return (
      <div
        className={`
          h-full grid grid-cols-2 gap-2 overflow-auto
          ${storeFullData?.products?.product_count && storeFullData?.products?.product_count < 3 ? "grid-rows-2" : ""}`
        }>
        {storeFullData?.products?.products_data?.map((product) => (
          <ProductCard productData={product} animated={false} key={product.product_id} />
        ))}
      </div>
    );
  };

  return (
    <Flex direction={{ base: 'column', sm: 'row' }} gap="md">
      {/* Blueprints Section */}
      <Box flex={{ base: '0 0 auto', sm: '0 0 45%' }}>
        <StoreInfoWrapper style={{ height: '100%' }}>
          <StoreInfoContent
            title="Blue Print"
            buttonText="Edit"
            onClick={editBlueprints}
          >
            {storeFullData?.blueprints?.blueprint_count ? (
              <div
                className={`
                  max-h-[400px] 
                  h-full 
                  grid 
                  grid-cols-2 
                  gap-2 
                  overflow-auto
                  ${storeFullData.blueprints.blueprint_count < 3 ? "grid-rows-2" : ""}`
                }
              >
                {storeFullData?.blueprints.blueprint_data?.map((blueprint) => (
                  <BlueprintCard blueprintData={blueprint} key={blueprint.id}>
                    <Flex gap={3} pos="absolute" bottom={5} left={5}>
                      {mockedBlueprintColors.map((color) => (
                        <Box
                          key={color}
                          w={10}
                          h={10}
                          bg={color}
                          style={{ borderRadius: "50%" }}
                        />
                      ))}
                    </Flex>
                  </BlueprintCard>
                ))}
              </div>
            ) : (
              <div className='h-full w-full flex justify-center items-center text-center'>
                No blueprints available
              </div>
            )}
          </StoreInfoContent>
        </StoreInfoWrapper>
      </Box>

      {/* Products Section */}
      <StoreInfoWrapper style={{ flex: '1 0 auto' }}>
        <StoreInfoContent
          title="Product"
          buttonText="Add"
          onClick={() => {
            if (isProductViable) {
              addProducts();
            }
          }}
        >
          {renderProductContent()}
        </StoreInfoContent>
      </StoreInfoWrapper>
    </Flex>
  );
});
