import { motion } from "framer-motion";

export const FinalScreen = () => {
    const container = {
        hidden: { opacity: 0 },
        show: {
            opacity: 1,
            transition: {
                staggerChildren: 0.3,
            }
        }
    };

    const item = {
        hidden: { opacity: 0, y: 20 },
        show: {
            opacity: 1,
            y: 0,
            transition: {
                duration: 0.8,
                ease: "easeOut"
            }
        }
    };

    return (
        <div className="h-full flex flex-col justify-between gap-5 md:gap-10">
            <motion.div
                className="flex flex-col gap-5 md:gap-10 text-gray-400 text-lg sm:text-xl md:text-2xl"
                variants={container}
                initial="hidden"
                animate="show"
            >
                <motion.span
                    variants={item}
                    className="text-black"
                >
                    Welcome to the future of AI-powered design! You're officially on our waitlist.
                </motion.span>

                <motion.span
                    variants={item}
                >
                    We'll review your profile and send you an exclusive access invitation soon.
                </motion.span>

                <motion.span
                    variants={item}
                >
                    Keep an eye on your inbox – we've got some creative surprises heading your way that will help you get started when we launch.
                </motion.span>
            </motion.div>
        </div>
    );
};
