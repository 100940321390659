import { mockedBlueprintColors, mockedBlueprintSizes } from "src/modules/mocked-data/mocked-data";

export const BlueprintDetailedInfo = () => {
    return <div className="flex gap-8">
        <div className="relative w-[65%] flex flex-col gap-5">
            <span className="font-medium">12.99$</span>
            <div className="flex gap-2">
                {mockedBlueprintColors.map((color) => (
                    <div key={color} className="w-5 h-5 rounded-full border border-solid" style={{ background: color }} />
                ))}
            </div>
            <div className="flex justify-between gap-5">
                <div className="flex flex-col">
                    <span className="font-medium">Made in</span>
                    <span className="text-gray-400">Brasil</span>
                </div>
                <div className="flex flex-col">
                    <span className="font-medium">Size</span>
                    <div className="flex gap-2 text-gray-400">
                        {mockedBlueprintSizes.map((size) => <span key={size}>{size.toUpperCase()}</span>)}
                    </div>
                </div>
            </div>
        </div>
        <div className="w-[30%] flex flex-col gap-5">
            <span className="font-medium">Composition</span>
            <div className="flex flex-col text-xs text-gray-400">
                <span>Elastane 5%</span>
                <span>Coton 90%</span>
                <span>Diamond 5%</span>
            </div>
            <button className="w-full bg-transparent text-sm hover:bg-gray-100 outline-none py-1.5 rounded-md border-[1px] cursor-pointer">
                Add to collection
            </button>
        </div>
    </div>
};
