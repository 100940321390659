import { FC } from "react";
import { Tab, TabData } from "src/modules/blueprints/types";

interface TabNavigationProps {
  activeTab: Tab;
  onTabChange: (tab: Tab) => void;
  className?: string;
}

export const TabNavigation: FC<TabNavigationProps> = ({ activeTab, onTabChange, className }) => {
  const tabs: TabData[] = [
    { type: 'collection', label: 'My Collection' },
    { type: 'discover', label: 'Discover' }
  ];

  return (
    <nav className={`flex gap-5 ${className}`}>
      {tabs.map(({ type, label }) => (
        <button
          key={type}
          onClick={() => onTabChange(type)}
          className={`px-4 py-1 border rounded-md text-gray-600  text-md  cursor-pointer outline-none transition-colors hover:bg-gray-100
          ${activeTab === type ? 'bg-gray-200' : 'bg-transparent'}
        `}
        >
          {label}
        </button>
      ))}
    </nav>
  );
};
