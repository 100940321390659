import { FC, useMemo } from "react";
import { FormikValues, useFormikContext } from "formik";

interface StepperProps {
    activeStep: number;
    setStep: (step: number) => void;
    stepsQuantity: number;
}

export const FormStepper: FC<StepperProps> = ({ activeStep, setStep, stepsQuantity }) => {
    const { submitForm, validateForm, errors, touched, values } = useFormikContext<FormikValues>();

    const stepFields = useMemo(() => ({
        1: ['name', 'subname', 'email', 'country'],
        2: ['businessModel', 'platforms'],
        3: ['businessLevel', 'challenges'],
        4: ['favoriteFeature'],
    }), [activeStep]);

    const hasStepErrors = useMemo(() => {
        const currentStepFields = stepFields[activeStep as keyof typeof stepFields] || [];

        return currentStepFields.some(field => {
            const value = values[field as keyof typeof values];
            return !value || errors[field as keyof typeof errors];
        });
    }, [errors, touched, activeStep, stepFields]);

    const handleNext = async () => {
        const currentStepFields = stepFields[activeStep as keyof typeof stepFields] || [];

        const stepErrors = await validateForm();

        const hasErrors = currentStepFields.some(field =>
            stepErrors[field as keyof typeof stepErrors]
        );

        !hasErrors && setStep(activeStep + 1);
    };

    const handleSubmit = async () => {
        const errors = await validateForm();

        if (Object.keys(errors).length === 0) {
            setStep(stepsQuantity);

            await submitForm();
        }
    };

    return (
        <div className="flex justify-between items-center gap-3">
            <button
                type="button"
                onClick={() => setStep(activeStep - 1)}
                className="bg-inherit outline-none border border-black text-black hover:bg-zinc-200 border-solid rounded-md text-nowrap py-2 px-8 leading-none cursor-pointer transition-colors duration-100 disabled:opacity-50 disabled:cursor-not-allowed"
                style={{ visibility: activeStep <= 1 || activeStep === stepsQuantity - 1 ? "hidden" : "visible" }}
            >
                Back
            </button>

            {activeStep === stepsQuantity - 1 ? (
                <button
                    type="button"
                    onClick={handleSubmit}
                    disabled={hasStepErrors}
                    className="bg-inherit outline-none border text-black border-black hover:bg-zinc-200 border-solid rounded-md text-nowrap text-lg py-2 md:py-3 px-10 md:px-[75px] leading-none cursor-pointer transition-colors duration-100 disabled:opacity-50 disabled:cursor-not-allowed"
                >
                    Join
                </button>
            ) : (
                <div className="flex">
                    {Array.from({ length: stepsQuantity }).map((_, i) => (
                        <div key={i} className="flex justify-center items-center w-6 h-4">
                            <div
                                className={`rounded-full transition-all duration-200 ${activeStep - 1 === i
                                    ? "bg-gray-800 h-[18px] w-[18px]"
                                    : "bg-gray-400 h-3 w-3"
                                    }`}
                            />
                        </div>
                    ))}
                </div>
            )}

            <button
                type="button"
                onClick={handleNext}
                className="bg-inherit outline-none border text-black border-black hover:bg-zinc-200 border-solid rounded-md text-nowrap py-2 px-8 leading-none cursor-pointer transition-colors duration-100 disabled:opacity-50 disabled:cursor-not-allowed"
                style={{ visibility: activeStep === stepsQuantity - 1 ? "hidden" : "visible" }}
                disabled={hasStepErrors}
            >
                Next
            </button>
        </div>
    );
};
