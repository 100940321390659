import { FC } from "react";
import { BlueprintModelCard, TabNavigation, VerticalScroll } from "src/modules/blueprints";
import { Blueprint } from "src/models/blueprint";
import FilterIcon from "src/assets/icons/filter-icon.png";
import { Tab } from "src/modules/blueprints/types";

interface MyCollectionProps {
    blueprintsData: Blueprint[];
    setActiveTab: (tab: Tab) => void;
    className?: string;
}

export const MyCollection: FC<MyCollectionProps> = ({ blueprintsData, setActiveTab, className }) => {
    const handleClick = () => {
        console.log("Click");
    };

    return (
        <VerticalScroll className={className}>
            <div className="w-full h-full flex flex-col gap-2 lg:gap-5 py-5 lg:py-10">
                <TabNavigation activeTab="collection" onTabChange={setActiveTab} className="md:hidden" />

                <h2 className="font-medium m-0">My Collection</h2>

                <div className="w-8 h-8 cursor-pointer">
                    <img src={FilterIcon} alt="Filter icon" />
                </div>

                <div className="flex flex-col gap-5 pb-5 lg:pb-10">
                    {blueprintsData.map((blueprint) => (
                        <BlueprintModelCard
                            key={blueprint.id}
                            blueprintData={blueprint}
                            buttonText="Edit"
                            onClick={handleClick}
                            type="collections"
                        />
                    ))}
                </div>
            </div>
        </VerticalScroll>
    );
};
