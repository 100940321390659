import { FC } from "react";
import { Field, useField } from "formik";

interface FormInputFieldProps {
    label: string;
    name: string;
    placeholder: string;
}

export const FormInputField: FC<FormInputFieldProps> = ({ label, name, placeholder }) => {
    const [field, meta] = useField({ name, type: "text" });

    return (
        <div className="flex flex-col gap-2">
            <span className="text-sm">{label}</span>
            <Field
                {...field}
                type="text"
                name={name}
                className="w-64 outline-none rounded-md bg-inherit py-2 px-4 leading-none text-sm placeholder:italic border border-black"
                placeholder={placeholder}
                autoComplete="off"
            />

            {meta.touched && meta.error && (
                <div className="text-red-500 text-sm">{meta.error}</div>
            )}
        </div>
    );
};
