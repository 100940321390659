import { FC, memo, useCallback, useState } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { useFormik } from 'formik';
import { Card, NumberInput } from '@mantine/core';
import { StoreSelector } from 'src/modules/store-creator/components/store-selector';
import { createPersona } from 'src/services/automation.service';
import { SQSPersonaMessage } from 'src/models/persona';
import { useStoreContext } from 'src/contexts';

interface MyPersonaFormProps {
  setCreationMessages: (messages: SQSPersonaMessage[]) => void
}

export const MyPersonaForm: FC<MyPersonaFormProps> = memo(({ setCreationMessages }) => {
  const { storeId } = useStoreContext();
  const [isLoading, setIsLoading] = useState(false);

  const { getAccessTokenSilently } = useAuth0();

  const formik = useFormik({
    initialValues: {
      store_id: storeId,
      quantity: "2",
      user_guidance: "",
    },
    enableReinitialize: true,
    onSubmit: async ({ store_id, quantity, user_guidance }) => {
      if (!store_id) return;

      try {
        setIsLoading(true);

        const token = await getAccessTokenSilently();
        const { persona_task_id, message } = (await createPersona(token, store_id, quantity, user_guidance)).data;

        const startMessage: SQSPersonaMessage = {
          task_id: persona_task_id,
          store_id: store_id,
          description: message,
          result: "",
          title: "Creation ...",
        }

        setCreationMessages([startMessage]);
      } catch (error) {
        console.log(error);
      } finally {
        setIsLoading(false);
      }
    },
  });

  const setStoreIdCallback = useCallback((id: number) => {
    formik.setFieldValue("store_id", id);
  }, [formik.setFieldValue]);

  return (
    <Card
      shadow="sm"
      padding="lg"
      radius="md"
      withBorder
      style={{
        borderColor: 'black',
        borderWidth: '1px',
        backgroundColor: '#fffbeb',
      }}
      className="shadow-md flex-[35%] h-[40vh] sm:w-[80%] md:w-[50%] lg:w-[35%] min-w-max m-0"
    >
      <form onSubmit={formik.handleSubmit} className="flex flex-col justify-center h-full border-1 border-black rounded-lg gap-5">
        <div className="flex flex-col md:flex-row justify-between items-center gap-2">
          <StoreSelector
            setStoreId={(id: number) => setStoreIdCallback(id)}
            className="w-40 bg-transparent rounded-sm border-gray-400"
          />

          <div className="flex items-center">
            <span className="mr-2 text-black text-nowrap">Creation of</span>
            <NumberInput
              size="xs"
              radius="xs"
              clampBehavior="strict"
              min={0}
              className="w-24 border-1 border-black rounded bg-[#fffbeb] text-black"
              value={Number(formik.values.quantity)}
              onChange={(value) => formik.setFieldValue("quantity", Number(value))}
              disabled={isLoading}
            />
            <span className="ml-2 text-black">Personas</span>
          </div>
        </div>

        <div className="flex-grow">
          <textarea
            name="user_guidance"
            value={formik.values.user_guidance}
            onChange={formik.handleChange}
            placeholder="Describe a bit your audience..."
            className="w-full h-full p-2 border-1 border-black rounded bg-[#fffbeb] text-black resize-none"
            disabled={isLoading}
          />
        </div>

        <div className="w-full flex justify-center">
          <button
            type="submit"
            className={`2xl:py-3 xl:py-1 px-8 md:px-12 disabled:bg-[#fffbeb] disabled:cursor-not-allowed font-semibold text-lg md:text-lg cursor-pointer text-black rounded transition-colors ${isLoading ? 'bg-gray-200 cursor-not-allowed'
              : 'bg-[#fffbeb] hover:bg-gray-100'
              }`}
            disabled={isLoading || !formik.values.store_id}
          >
            {isLoading ? 'Processing...' : 'Launch creation'}
          </button>
        </div>
      </form>
    </Card>
  );
});
