import { Field, useFormikContext } from "formik";
import { FormOptions } from "src/modules/waitlist-from";
import { formData } from "src/modules/waitlist-from/data";

interface SecondStepValues {
    businessModel: string;
    otherBusinessModel?: string;
    platforms: string[];
    otherPlatform?: string;
}

export const SecondStep = () => {
    const { errors, touched, values, setFieldTouched } = useFormikContext<SecondStepValues>();

    return (
        <>
            <div className="flex flex-col gap-5">
                <span className="text-xl">At first, can you specify what best describes your business model?</span>
                <FormOptions fieldName="businessModel" options={formData.businessModels} />

                {touched.businessModel && errors.businessModel && (
                    <div className="text-red-500 text-sm">{errors.businessModel}</div>
                )}

                {values.businessModel === "Other" && (
                    <Field
                        name="otherBusinessModel"
                        as="textarea"
                        min={3}
                        placeholder="Please specify your platform in few words..."
                        className="resize-none p-3 flex-grow bg-inherit rounded-md border-black placeholder:text-gray-400 placeholder:italic placeholder:text-sm"
                        onFocus={() => setFieldTouched('otherBusinessModel', true)}
                    />
                )}
            </div>

            <div className="flex-grow flex flex-col gap-5">
                <div className="flex flex-col gap-1">
                    <span className="text-xl">Which platform(s) do you use to sell products?</span>
                    <span className="text-gray-400 italic text-sm">Multiple choices are possible</span>
                </div>

                <FormOptions fieldName="platforms" options={formData.platforms} multiple />

                {touched.platforms && errors.platforms && (
                    <div className="text-red-500 text-sm">{errors.platforms}</div>
                )}

                {values.platforms?.includes("Other") && (
                    <Field
                        name="otherPlatform"
                        as="textarea"
                        placeholder="Please specify your platform in few words..."
                        className="resize-none p-3 flex-grow bg-inherit rounded-md border-black placeholder:text-gray-400 placeholder:italic placeholder:text-sm"
                        onFocus={() => setFieldTouched('otherPlatform', true)}
                    />
                )}
            </div>
        </>
    );
};
