import { memo, useRef } from "react";
import { Box, Image } from "@mantine/core";
import { AnimatedText } from "../../components";
import { motion } from "framer-motion";

import _ScrollTrigger from "gsap/ScrollTrigger";
interface BenefitsBlockProps {
  heading: string;
  description: string;
  image: string;
  className?: string;
}

export const BenefitsBlock = memo<BenefitsBlockProps>(
  ({ heading, description, image, className }) => {
    const container = useRef<HTMLDivElement | null>(null);

    return (
      <div ref={container} className={`flex flex-col justify-between ${className}`}>
        <motion.div
          initial={{ opacity: 0, y: -100 }} // Initial state (hidden and moved down)
          whileInView={{ opacity: 1, y: 0 }} // When the div enters the view (fade in and move up)
          transition={{ duration: 0.7 }} // Smooth transition
          viewport={{ once: true, amount: 0.2 }} // Trigger when 30% of the element is in view
        >
          <Image
            h="35vh"
            style={{ objectFit: "cover" }}
            fit="fill"
            src={image}
          />
        </motion.div>

        <div className="p-5 lg:p-10 pb-[100px] flex flex-col justify-between gap-3 lg:gap-5 flex-grow">
          <AnimatedText
            speed={1000}
            fw={500}
            textSize={{ base: 26, sm: 28, lg: 38 }}
            textContent={heading}
          />

          <Box className="flex-grow">
            <AnimatedText
              speed={4000}
              fw={500}
              delay={0.4}
              textSize={18}
              textContent={description}
            />
          </Box>
        </div>
      </div>
    );
  }
);
