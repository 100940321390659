import { FC } from "react";
import { useField } from "formik";
import { Slider } from "@mantine/core";

interface RateFieldOption {
    label: string;
    name: string;
}

interface RateFieldProps {
    option: RateFieldOption;
}

export const RateField: FC<RateFieldProps> = ({ option }) => {
    const [field, _, helpers] = useField(`importanceOfFeatures.${option.name}`);

    return (
        <div className="flex gap-5 h-10 items-center">
            <span className="w-[30%] text-sm">{option.label}</span>
            <Slider
                flex="1 0 auto"
                thumbChildren={<span className="text-white">{field.value || 1}</span>}
                value={field.value || 1}
                onChange={(value) => helpers.setValue(value)}
                p={10}
                min={1}
                max={5}
                label={null}
                color="black"
                size={1.5}
                thumbSize={30}
                styles={{
                    thumb: { borderWidth: 3, backgroundColor: "black", borderColor: "black" },
                }}
            />
        </div>
    );
};
