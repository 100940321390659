export const formData = {
    businessModels: ["Print-on-Demand (POD)", "E-commerce/Dropshipping", "Creative Agency", "Other"],
    platforms: ["Shopify", "WooCommerce", "Amazon", "Etsy", "Other"],
    businessLevels: ["Early growth", "Established business", "High growth"],
    challenges: ["Shopify", "WooCommerce", "Amazon", "Etsy", "Other"],
    rateOptions: [
        { label: "Predictive trend analysis", name: "trendAnalysis" },
        { label: "AI-driven design creation", name: "designCreation" },
        { label: "Inventory management optimization", name: "optimization" },
        { label: "Real-time analytics", name: "analytics" }
    ],
    features: ["Predictive trend analysis", "AI-driven design automation", "Inventory and pricing optimization", "End-to-end order management"],
    contactInfo: [
        { label: "Name", name: "name", placeholder: "John" },
        { label: "Subname", name: "subname", placeholder: "Smith" },
        { label: "E-Mail", name: "email", placeholder: "mail@exemple.com" },
        { label: "Website link", name: "websiteLink", placeholder: "www.website.com" },
        { label: "Social media link", name: "socialMediaLink", placeholder: "Paste the url here" },
    ],
};
