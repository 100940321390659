import * as Yup from 'yup';
import { formData } from "src/modules/waitlist-from/data";
import { JoinWaitlistValues } from "src/modules/waitlist-from/types";

export const validationWaitlistFormSchemas = [
    Yup.object({
        name: Yup.string().required('Name is requried'),
        subname: Yup.string().required('Subname is requried'),
        email: Yup.string().email('Invalid email').required('Email is requried'),
        country: Yup.string().required('Please select your country'),
        notificationsEnabled: Yup.boolean()
    }),
    Yup.object({
        businessModel: Yup.string().required('Please select a business model'),
        platforms: Yup.array().min(1, 'Please select at least one platform'),
    }),
    Yup.object({
        businessLevel: Yup.string().required('Please select your business level'),
        challenges: Yup.string().required('Please select your biggest challenge'),
    }),
    Yup.object({
        favoriteFeature: Yup.string().required('Please select your favorite feature')
    }),
];

export const initialWaitlistFormValues: JoinWaitlistValues = {
    name: "",
    subname: "",
    email: "",
    websiteLink: "",
    socialMediaLink: "",
    businessModel: "",
    otherBusinessModel: "",
    platforms: [],
    otherPlatform: "",
    businessLevel: "",
    challenges: "",
    otherChallenge: "",
    timeSpent: 0,
    importanceOfFeatures: formData.rateOptions.reduce((acc, option) => ({ ...acc, [option.name]: 1 }), {}),
    favoriteFeature: "",
    country: "",
    notificationsEnabled: false,
};
