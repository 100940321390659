import { useFormikContext } from "formik";
import { FormOptions, RateField } from "src/modules/waitlist-from";
import { formData } from "src/modules/waitlist-from/data";

interface FourthStepValues {
    importanceOfFeatures: Record<string, number>;
    favoriteFeature: string;
}

export const FourthStep = () => {
    const { errors, touched } = useFormikContext<FourthStepValues>();

    return (
        <>
            <div className="flex flex-col gap-3">
                <span className="text-xl">Which features are essential for your business?</span>
                <span className="text-gray-400 italic text-sm">Rate from 1 to 5</span>

                <div className="flex flex-col gap-5">
                    {formData.rateOptions.map((option) => (
                        <RateField key={option.name} option={option} />
                    ))}
                </div>
            </div>

            <div className="flex flex-col gap-5 flex-grow">
                <span className="text-xl">Which M-AGI-C feature excites you the most?</span>
                <FormOptions fieldName="favoriteFeature" options={formData.features} />

                {touched.favoriteFeature && errors.favoriteFeature && (
                    <div className="text-red-500 text-sm">{errors.favoriteFeature}</div>
                )}
            </div>
        </>
    );
};
