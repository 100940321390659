import { FC, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { NavLink, Flex } from '@mantine/core';
import { useAuth0 } from "@auth0/auth0-react";

interface MenuTabsProps {
  textAlign?: "center" | "left";
  onLinkClick?: () => void;
}

export const MenuTabs: FC<MenuTabsProps> = ({ textAlign, onLinkClick }) => {
  const navigate = useNavigate();
  const { logout } = useAuth0();

  const handleLogout = () => {
    logout({
      logoutParams: {
        returnTo: window.location.origin,
      },
    });
  };

  const menuLinksProperties = useMemo(
    () => [
      {
        links: [
          {
            label: 'Home',
            onClick: () => navigate('/store-info'),
            key: '/store-info',
          },
          {
            label: 'Persona',
            onClick: () => navigate('/my-personas'),
            key: '/my-personas',
          },
          {
            label: 'Product',
            onClick: () => navigate('/products'),
            key: '/products',
          },
          {
            label: 'Artwork',
            onClick: () => navigate('/artworks'),
            key: '/artworks',
          },
        ],
      },
      {
        links: [
          {
            label: 'Settings',
            onClick: () => navigate('/'),
            key: '/settings',
          },
          {
            label: 'Plan',
            onClick: () => navigate('/'),
            key: '/plan',
          },
          {
            label: 'Affilate plan',
            onClick: () => navigate('/-'),
            key: '/affilate-plan',
          },
          {
            label: 'Contact us',
            onClick: () => navigate('/'),
            key: '/contact-us',
          },
          {
            label: 'Logout',
            onClick: handleLogout,
            key: '/logout',
          },
        ],
      },
    ],
    [navigate, handleLogout]
  );

  return (
    <Flex
      h="75%"
      w="100%"
      px="20%"
      direction="column"
      justify="space-between"
      gap={20}
      py={40}
    >
      {menuLinksProperties.map(({ links }, index) => (
        <Flex key={index} direction="column" gap={5}>
          {links.map(({ key, onClick, label }) => (
            <NavLink
              key={key}
              p={0}
              ta={textAlign === "center" ? "center" : "left"}
              bg="white"
              styles={{
                label: { fontSize: 18, lineHeight: 1 },
              }}
              onClick={() => {
                onClick();
                onLinkClick && onLinkClick();
              }}
              label={label}
            />
          ))}
        </Flex>
      ))}
    </Flex>
  );
};
