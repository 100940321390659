import { FC, memo, PropsWithChildren, ReactNode, useEffect, useRef, useState } from 'react';
import { motion, useMotionValue } from 'framer-motion';
import { SliderControl } from 'src/modules/products-slider/components';

interface HorizontalCardsGridProps {
  controllerPosition?: "top" | "bottom";
  title?: string;
  footer?: ReactNode;
  className?: string;
}

export const HorizontalCardsGrid: FC<HorizontalCardsGridProps & PropsWithChildren> = memo(
  ({ children, title, controllerPosition = "bottom", footer, className = '' }) => {
    const x = useMotionValue(0);
    const containerRef = useRef<HTMLDivElement>(null);
    const contentRef = useRef<HTMLDivElement>(null);
    const [dragConstraints, setDragConstraints] = useState({ right: 0, left: 0 });

    const calculateConstraints = () => {
      if (containerRef.current && contentRef.current) {
        const containerWidth = containerRef.current.offsetWidth;
        const contentWidth = contentRef.current.scrollWidth;

        const newConstraints = {
          right: 0,
          left: -Math.max(0, contentWidth - containerWidth)
        };

        setDragConstraints(newConstraints);
      }
    };

    useEffect(() => {
      // Create an intersection observer to detect when the component becomes visible
      const observer = new IntersectionObserver(
        (entries) => {
          entries.forEach(entry => {
            if (entry.isIntersecting) {
              setTimeout(calculateConstraints, 0);
            }
          });
        },
        { threshold: 0 }
      );

      containerRef.current && observer.observe(containerRef.current);

      // Mutation observer to detect display/visibility changes
      const mutationObserver = new MutationObserver((mutations) => {
        mutations.forEach((mutation) => {
          if (mutation.type === 'attributes' &&
            (mutation.attributeName === 'style' || mutation.attributeName === 'class')) {
            setTimeout(calculateConstraints, 0);
          }
        });
      });

      if (containerRef.current) {
        mutationObserver.observe(containerRef.current, {
          attributes: true,
          attributeFilter: ['style', 'class']
        });
      }

      // Resize observer for size changes
      const resizeObserver = new ResizeObserver(() => {
        setTimeout(calculateConstraints, 0);
      });

      containerRef.current && resizeObserver.observe(containerRef.current);
      contentRef.current && resizeObserver.observe(contentRef.current);

      calculateConstraints();

      return () => {
        observer.disconnect();
        mutationObserver.disconnect();
        resizeObserver.disconnect();
      };
    }, [children]);

    const handleWheel = (event: WheelEvent) => {
      event.preventDefault();

      const currentX = x.get();
      const deltaX = event.deltaY;
      const newX = Math.max(
        dragConstraints.left,
        Math.min(dragConstraints.right, currentX - deltaX)
      );

      x.set(newX);
    };

    useEffect(() => {
      const container = containerRef.current;

      if (container) {
        container.addEventListener('wheel', handleWheel, { passive: false });
        return () => container.removeEventListener('wheel', handleWheel);
      }

      return
    }, [dragConstraints]);

    return (
      <div className="relative w-full h-full flex flex-col gap-3 md:gap-5">
        {controllerPosition === "top" &&
          <div className="flex justify-center items-center w-full gap-3 md:gap-5">
            {title && <span className="text-xl text-gray-500">{title}</span>}
            <SliderControl x={x} constraints={dragConstraints} />
          </div>
        }

        <div
          ref={containerRef}
          className="w-full flex-1 flex items-center overflow-hidden"
        >
          <motion.div
            ref={contentRef}
            drag="x"
            dragConstraints={dragConstraints}
            dragElastic={0.1}
            dragTransition={{ bounceStiffness: 100, bounceDamping: 20 }}
            style={{ x }}
            className={`flex gap-5 h-1/2 justify-center items-center touch-none cursor-pointer ${className}`}
          >
            {children}
          </motion.div>
        </div>

        {footer && footer}

        {controllerPosition === "bottom" &&
          <div className="flex justify-center w-full">
            <SliderControl x={x} constraints={dragConstraints} />
          </div>
        }
      </div>
    );
  }
);
