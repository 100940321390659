import { motion } from "framer-motion";

export const StartScreen = () => (
    <div className="h-full flex flex-col justify-between gap-5 md:gap-10">
        <motion.div
            className="flex flex-col gap-5 md:gap-10 text-gray-400 text-lg sm:text-xl md:text-2xl"
            initial={{ opacity: 0, y: 50 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ delay: 0.5, duration: 1 }}
        >
            <span>
                Thank you for your interest! To help us create
                <motion.span
                    initial={{ color: "rgb(156, 163, 175)" }}
                    animate={{ color: "rgb(0, 0, 0)" }}
                    transition={{ delay: 2, duration: 1.5 }}
                >
                    {" "}the best possible experience for you
                </motion.span>
                , we'd love to learn a bit more about your creative journey.
            </span>

            <motion.span
                initial={{ opacity: 0, y: 50 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ delay: 1, duration: 0.5 }}
            >
                Please take a moment to answer a few quick questions.
            </motion.span>
        </motion.div>

        <motion.div
            className="h-[1px] bg-black"
            initial={{ width: 0 }}
            animate={{ width: "70%" }}
            transition={{ delay: 1.2, duration: 3 }}
        />
    </div>
);
