import { memo, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';
import { Button, Flex, LoadingOverlay } from '@mantine/core';
import { StoreInfoContent } from 'src/modules/store-info/components/store-info-content';
import { StoreInfoWrapper } from 'src/modules/store-info/components/store-info-wrapper';
import { FormTextarea } from 'src/modules/store-info/components/form-textarea';
import { updateStore } from 'src/services/automation.service';
import { useStoreContext } from 'src/contexts';
import { useMutation } from '@tanstack/react-query';

export const StoreDescription = memo(() => {
  const { storeFullData } = useStoreContext();
  const [editMode, setEditMode] = useState(false);
  const [description, setDescription] = useState(
    storeFullData?.description || ''
  );
  useEffect(() => {
    setDescription(storeFullData?.description || '');
    return () => { };
  }, [storeFullData]);

  const { mutate: selectStoreUpdateMutation, isPending: isStoreUpdatePending } =
    useMutation({
      mutationFn: () => {
        return updateStorehandler();
      },
    });
  const updateStorehandler = async () => {
    if (!storeFullData?.id) return;

    try {
      const accessToken = await getAccessTokenSilently();
      const updatedInfo = {
        ...storeFullData,
        store_id: storeFullData.id,
        store_name: storeFullData.name,
        store_country: storeFullData.country,
        store_description: description,
      };
      const { data, error } = await updateStore(accessToken, updatedInfo);
    } catch (error) {
      console.error('Error updating store:', error);
    }
  };
  const navigate = useNavigate();
  const { getAccessTokenSilently } = useAuth0();

  const saveDescription = async () => {
    if (!storeFullData?.id) return;

    selectStoreUpdateMutation();
  };

  const changeEditMode = () => {
    if (editMode) saveDescription();
    setEditMode(!editMode);
  };

  const handleStoreSelection = () => {
    navigate('/store-creator');
  };

  return (
    <StoreInfoWrapper>
      <LoadingOverlay
        visible={isStoreUpdatePending}
        zIndex={10000}
        overlayProps={{ radius: 'sm', blur: 2 }}
      />
      <Flex direction={{ base: 'column', md: 'row' }} w="100%" h="100%">
        <StoreInfoContent
          title={storeFullData?.name || ''}
          buttonText={editMode ? 'Save' : 'Edit'}
          onClick={changeEditMode}
        >
          <FormTextarea
            editMode={editMode}
            text={description}
            onChange={setDescription}
          />
        </StoreInfoContent>
      </Flex>
    </StoreInfoWrapper>
  );
});
