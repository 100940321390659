import { memo } from "react";
import { Box } from "@mantine/core";
import { AnimatedText } from "../../../components";
import { SlideNumber } from "../slide-number";
import gsap from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { useGSAP } from "@gsap/react";

import firstImage from "../../../../../assets/Landing/ThirdPage-1.avif";
import secondImage from "../../../../../assets/Landing/ThirdPage-2.avif";

gsap.registerPlugin(useGSAP);
gsap.registerPlugin(ScrollTrigger);

export const SecondSlide = memo(() => {
  return (
    <Box className="slide" w="100vw" h="100%" display="flex" pos="relative">
      <div
        className="flex flex-col items-center w-full lg:w-[30%] flex-grow flex-shrink-0 overflow-hidden"
        style={{ borderRight: "1px solid black", borderLeft: "1px solid black" }}
      >
        <Box
          w="100%"
          h="65vh"
          style={{
            backgroundImage: `url(${firstImage})`,
            backgroundSize: "cover",
            backgroundPosition: "right",
          }}
        />

        <div className="p-5 lg:p-10 pb-[100px]">
          <AnimatedText
            fw={500}
            textSize={18}
            speed={5000}
            textContent="With M-AGI-C, managing your print-on-demand operations becomes effortless and highly effective. Connect your store, and let our AI analyze market trends, automatically generate captivating designs, and optimize your stock and pricing—all in just one click, with zero logistical worries."
          />
        </div>
      </div>

      <div className="relative hidden lg:flex flex-col items-end justify-between w-[65%] pt-5 lg:pt-10 pl-5 lg:pl-10 gap-5 lg:gap-10">
        <AnimatedText
          fw={500}
          textSize={38}
          speed={1000}
          textContent="Transform Your POD Business with Seamless AI Integration"
        />

        <Box
          w="100%"
          h="75%"
          style={{
            backgroundImage: `url(${secondImage})`,
            backgroundSize: "cover",
            backgroundPosition: "right",
            alignSelf: "flex-end",
            flexGrow: 1
          }}
        />
      </div>

      <SlideNumber slideNumber={2} side="right" color="light" />

      <div className="hidden md:block lg:hidden">
        <SlideNumber slideNumber={2} side="right" color="dark" />
      </div>
    </Box>
  );
});
