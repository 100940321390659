import { PageLayout } from "src/components/page-layout";
import { Loader } from "@mantine/core";

export const FullPageLoader = () => {
    return (
        <PageLayout>
            <div className='h-full flex justify-center items-center'>
                <Loader />
            </div>
        </PageLayout>
    )
};
