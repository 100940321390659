import { useRef, FC, useMemo } from "react";
import { BlueprintCard } from "src/modules/blueprints";
import { CheckboxCardElem } from "src/modules/products-slider";
import { BlueprintInfoElem } from "src/modules/product-creation-form";
import { Blueprint, SelectedBlueprintType } from "src/models/blueprint";

interface CreatingFormBlueprintProps {
  blueprintData: Blueprint;
  selectedBlueprints: SelectedBlueprintType[];
  handleBlueprintSelect: (selectedId: string) => void;
  handlePriceChange: (blueprintId: string, value: string) => void;
  handleQuantityChange: (blueprintId: string, value: string) => void;
}

export const CreatingFormBlueprint: FC<CreatingFormBlueprintProps> = ({
  blueprintData,
  selectedBlueprints,
  handleBlueprintSelect,
  handlePriceChange,
  handleQuantityChange,
}) => {
  const blueprintCard = useRef<HTMLDivElement | null>(null);

  const cardWidth = useMemo(() => {
    const minWidth = 195;
    const maxWidth = document.body.offsetHeight * 0.25;

    const cardHeight = blueprintCard.current?.offsetHeight || maxWidth;

    return Math.max(minWidth, Math.min(cardHeight, maxWidth));
  }, [blueprintCard.current?.offsetHeight]);

  return (
    <div
      key={blueprintData.id}
      className="flex flex-col h-full items-center overflow-hidden 2xl:aspect-[5/7]"
      style={{
        width: `${cardWidth}px`,
      }}
    >
      <div
        ref={blueprintCard}
        className="relative flex-grow flex justify-center items-center w-full overflow-hidden"
      >
        <CheckboxCardElem
          id={String(blueprintData.id)}
          selectedIds={selectedBlueprints.map(({ id }) => String(id))}
          checkboxSize="sm"
          handleSelect={handleBlueprintSelect}
        >
          <BlueprintCard
            blueprintData={blueprintData}
            className="min-h-32 aspect-square"
          />
        </CheckboxCardElem>
      </div>
      <BlueprintInfoElem
        title={blueprintData.title}
        changePrice={(value) =>
          handlePriceChange(String(blueprintData.id), value)
        }
        changeQuantity={(value) =>
          handleQuantityChange(String(blueprintData.id), value)
        }
      />
    </div>
  );
};
