import { memo } from 'react';
import { Box, Divider } from '@mantine/core';
import { AnimatedText } from '../../../components';
import { SlideNumber } from '../slide-number';

import SecondImage from '../../../../../assets/Landing/FourthPage-2.avif';
import FirstImage from '../../../../../assets/Landing/FourthPage-1.avif';

export const FourthSlide = memo(() => {
  return (
    <Box
      className="slide"
      w="100vw"
      h="100%"
      display="flex"
      pos="relative"
    >
      <div className='w-3/5 flex-none hidden md:block'>
        <Box
          w="100%"
          h="100%" // Ensure it takes full height
          style={{
            backgroundImage: `url(${FirstImage})`,
            backgroundSize: 'cover',
            backgroundPosition: 'right',
          }}
        />
      </div>

      <Divider color="black" orientation="vertical" />

      <div className='flex flex-col w-full md:w-2/5 gap-5 lg:gap-10 md:pl-5 lg:pl-10 pt-5 lg:pt-10'>
        <div className='flex flex-col gap-5 px-5 md:px-0 md:pr-5'>
          <AnimatedText
            textSize={{ base: 26, sm: 28, lg: 38 }}
            fw={500}
            speed={1000}
            textContent="Ready to Revolutionize Your POD Business?"
          />

          <AnimatedText
            delay={0}
            fw={500}
            speed={3000}
            textSize={18}
            textContent="Join our exclusive beta program and be among the first to discover how AI can transform your print-on-demand business. Easily integrate with major tech partners and become part of a forward-thinking community of POD entrepreneurs."
          />
        </div>

        <div
          className="w-full flex-none basis-3/5 bg-cover bg-no-repeat bg-right flex-grow"
          style={{ backgroundImage: `url(${SecondImage})` }}
        />
      </div>

      <SlideNumber slideNumber={4} side="left" color="light" />
    </Box>
  );
});
