import { Modal, Button } from "@mantine/core";

export const NoStoresModal = () => {
    return (
        <Modal centered opened withCloseButton={false} onClose={() => null}>
            <div className="flex flex-col gap-2 items-center text-center">
                <div className="flex flex-col text-lg">
                    <span>No stores found</span>
                    <span>Please add a store on the supplier's webpage</span>
                </div>
                <Button component="a" href="/onboarding" fz={16}>
                    Add a store
                </Button>
            </div>
        </Modal>
    );
};
