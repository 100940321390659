import { memo, useEffect, useState } from 'react';
import { Button, SimpleGrid } from '@mantine/core';
import { StoreInfoWrapper } from 'src/modules/store-info/components/store-info-wrapper';
import { StoreInfoContent } from 'src/modules/store-info/components/store-info-content';
import { useStoreContext } from 'src/contexts';
import { useNavigate } from 'react-router-dom';
import { PersonaCard } from 'src/modules/personas-screens/components';

export const StorePersona = memo(() => {
  const { storeFullData, isLoading } = useStoreContext();

  const navigate = useNavigate();

  const addPersona = () => {
    navigate('/my-personas');
  };

  const [isPersonaViable, setIsPersonaViable] = useState(false);

  useEffect(() => {
    if (storeFullData?.country?.length && storeFullData?.description?.length) {
      setIsPersonaViable(true);
    } else {
      setIsPersonaViable(false);
    }

    return () => { };
  }, [storeFullData, isLoading]);

  return (
    <StoreInfoWrapper>
      <StoreInfoContent
        title="Main persona used"
        buttonText="Add"
        onClick={() => {
          if (isPersonaViable) {
            addPersona();
          }
        }}
      >
        <div className="h-full overflow-auto">
          {storeFullData?.persona?.persona_count ? (
            <div
              className={`
              h-full grid grid-cols-2 md:grid-cols-4 gap-2 overflow-auto
             ${storeFullData.persona.persona_count < 3 ? "grid-rows-2" : ""}`
              }>
              {storeFullData?.persona?.persona_data?.map((persona) => (
                <PersonaCard personaData={persona} animated={false} key={persona.persona_id} />
              ))}
            </div>
          ) : (
            <div className="grid h-full place-items-center ">
              <div className="flex flex-col gap-2 items-center justify-center">
                <Button
                  style={{ width: 200 }}
                  variant="outline"
                  color="black"
                  size="sm"
                  disabled={!isPersonaViable}
                  className="get-started-btn"
                  onClick={addPersona}
                >
                  Add Persona
                </Button>
                {!isPersonaViable && (
                  <div>
                    Please Final Your Store Information first to do so{' '}
                    <a className="text-blue-500" href="/onboarding">
                      Click here
                    </a>
                  </div>
                )}
              </div>
            </div>
          )}
        </div>
      </StoreInfoContent>
    </StoreInfoWrapper>
  );
});
