import { FC, memo, MutableRefObject, useRef, useEffect } from 'react';
import gsap from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import { Flex } from '@mantine/core';

gsap.registerPlugin(ScrollTrigger);
interface ThirdScreenProps {
  buttonRef: MutableRefObject<HTMLButtonElement | null>;
}

export const ThirdScreen: FC<ThirdScreenProps> = memo(({ buttonRef }) => {
  const container = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    if (!container.current || !buttonRef.current) return;

    const ctx = gsap.context(() => {
      gsap.to(container.current, {
        scrollTrigger: {
          trigger: container.current,
          start: 'top top',
          end: 'bottom top',
          pin: true,
          pinSpacing: false,
        },
      });

      gsap.to(buttonRef.current, {
        position: 'fixed',
        scale: 1.2,
        top: '60vh',
        scrollTrigger: {
          trigger: container.current,
          end: 'center center',
          scrub: true,
        },
      });
    }, container);

    return () => {
      ctx.revert();
    };
  }, [buttonRef]);

  return <Flex ref={container} h="200vh" id="third-screen" />;
});
