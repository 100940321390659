import {
  FC,
  useContext,
  PropsWithChildren,
  memo,
  createContext,
  useMemo,
  useState,
  useEffect,
} from 'react';
import { useQuery } from '@tanstack/react-query';
import { useAuth0 } from '@auth0/auth0-react';
import { getUserStores, readStore } from 'src/services/automation.service';
import { StoreContextType } from 'src/modules/store-info/types/context-type';
import {
  StoreBasicDataType,
  StoreFullDataType
} from 'src/modules/store-creator/types/store-data';

const initialValue: StoreContextType = {
  stores: [],
  setStores: () => { },
  storeId: null,
  storeFullData: null,
  storeName: '',
  setStoreName: () => { },
  isLoading: false,
};

const StoreContext = createContext<StoreContextType>(initialValue);

export const StoreProvider: FC<PropsWithChildren> = memo(({ children }) => {
  const [stores, setStores] = useState<StoreBasicDataType[]>([]);
  const [storeId, setStoreId] = useState<number | null>(null);
  const [storeName, setStoreName] = useState('');
  const [storeFullData, setStoreFullData] = useState<StoreFullDataType | null>(null);
  // const [isFetchingStores, setIsFetchingStores] = useState(false);

  const { getAccessTokenSilently } = useAuth0();

  const { data: storesData, isLoading: isFetchingStores, isSuccess: isReadingStoreSuccess } = useQuery({
    queryKey: ['stores'],
    queryFn: async () => {
      const token = await getAccessTokenSilently();
      return getUserStores(token);
    },
  });

  const storesList: StoreBasicDataType[] = useMemo(() => {
    return isReadingStoreSuccess ? storesData?.data : [];
  }, [storesData]);

  useEffect(() => {
    setStores(storesList);

    if (!storeId) {
      const defaultStore = storesList[0];
      defaultStore && setStoreId(defaultStore.store_id);
    }
  }, [storesList]);

  // const fetchDefaultStore = useCallback(async () => {
  //   const token = await getAccessTokenSilently();
  //   setIsFetchingStores(true);

  //   const storesData = (await getUserStores(token)).data || [];
  //   setStores(storesData);

  //   const defaultStore = storesData[0];
  //   defaultStore && setStoreId(defaultStore.store_id);

  //   setIsFetchingStores(false);
  // }, [getAccessTokenSilently]);

  // useEffect(() => {
  //   fetchDefaultStore();
  // }, [fetchDefaultStore]);

  const {
    data: readStoreQuery,
    isLoading: isStoreReading,
    isSuccess,
  } = useQuery({
    queryKey: ['read-store', storeId],
    queryFn: async () => {
      if (!storeId) return null;
      const token = await getAccessTokenSilently();
      return readStore(token, storeId);
    },
    enabled: !!storeId,
  });

  useEffect(() => {
    if (isSuccess && readStoreQuery?.data) {
      const storeData = readStoreQuery.data as StoreFullDataType;
      setStoreFullData(storeData);
      setStoreName(storeData.name || '');
    }
  }, [isSuccess, readStoreQuery]);

  const contextValue = useMemo(
    () => ({
      stores,
      setStores,
      storeId,
      setStoreId,
      storeFullData,
      setStoreFullData,
      isLoading: isFetchingStores || isStoreReading,
      storeName,
      setStoreName,
    }),
    [stores, storeFullData, isFetchingStores, isStoreReading, storeId, storeName]
  );

  return (
    <StoreContext.Provider value={contextValue}>
      {children}
    </StoreContext.Provider>
  );
});

export const useStoreContext = () => useContext(StoreContext);
